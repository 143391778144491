const rulesStore = {
  state: {
    rulesConfig: {},
    defaults: {
      boxTypeChoiceGoal: "most-items",
      itemInitialOrientationPreferred: true,
      itemOrientationSearchDepth: 1,
      n: 0,
    },
  },
  mutations: {
    setRules(state, val) {
      state.rulesConfig = val;
    },
  },
  actions: {},
  getters: {},
};
export default rulesStore;
