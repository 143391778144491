<template>
  <div class="carton-wrap" v-if="box && box.items">
    <div class="box-header">
      <div v-if="svg.length > 0" v-html="svg" class="svg-wrap"></div>
      <!-- <strong>{{Object.keys(png)}}</strong> -->
      <img
        v-if="png && Object.keys(png).length > 0 && svg.length == 0"
        :src="renderImg(png)"
      />
      <ul class="pack-details">
        <li>
          <strong>{{ box.name }}<br />${{ box.price / 100 }}</strong>
        </li>
        <!-- <li>
          <span>Container Dimensions</span>[{{
            `${box.dimensions["x"]}, ${box.dimensions["y"]}, ${box.dimensions["z"]}`
          }}]
        </li> -->
        <li class="item-count">
          <strong>Items {{ box.items.length }}</strong>
        </li>
        <!-- <li class="column">
          <span>Weight Total</span> {{ box.weightUsed.toFixed(2) }}
        </li>
        <li>
          <span>Weight Used</span>
          {{ (box.weightUtilization * 100).toFixed(2) }}%
          <small
            >{{ box.weightNet.toFixed(2) }} /
            {{ box.weightMax.toFixed(2) }}</small
          >
        </li>
        <li>
          <span>Volume used</span>
          {{ (box.volumeUtilization * 100).toFixed(2) }}%
          <small
            >{{ box.volumeNet.toFixed(2) }} /
            {{ box.volumeMax.toFixed(2) }}</small
          >
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "CartonDetail",
  props: {
    box: {
      type: Object,
      default() {
        return {};
      },
    },
    svg: {
      type: [String],
      default: "",
    },
    png: {
      type: Object,
    },
  },
  methods: {
    renderImg(image) {
      return `data:image/${image.format};base64,${image.data}`;
    },
  },
};
</script>
<style lang="scss">
.carton-wrap {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  width: calc(33% - 2rem);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 8px rgba(146, 142, 161, 0.08);
  &:hover {
    filter: drop-shadow(0px 8px 8px rgba(133, 107, 255, 0.08))
      drop-shadow(0px 8px 16px rgba(133, 107, 255, 0.06));
  }

  button {
    display: block;
    width: 100%;
  }
  div {
    &.svg-wrap {
      display: inline-block;
    }
    &.box-header {
      width: 100%;
      padding: 0 2em 0 0;
    }
    figure,
    img {
      // float:left;
      height: 80px;
      margin: 1rem 1.2rem;

      svg {
        max-height: 80px;
        height: 100%;
      }
      figcaption {
        display: none;
      }
      polygon {
        transform: translateY(0);
        position: relative;
        transition: transform 0.3s, fill 0.3s, fill-opacity 0.3s,
          stroke-opacity 0.3s;
      }
      polygon.volume-line {
        stroke: #000;
        stroke-width: 1;
        fill-opacity: 0.9;
        stroke-opacity: 0.8;
      }
      &.x-ray {
        polygon {
          fill-opacity: 0.1;
          stroke-opacity: 0.2;
          &.active {
            fill-opacity: 1 !important;
            stroke-opacity: 1 !important;
            // transform: translateY(-5px);
          }
        }
      }
    }
    ul {
      display: inline-block;
      width: calc(100% - 180px);
      vertical-align: top;
      padding: 0;
      li {
        list-style-type: none;
        width: 100%;
        strong {
          width: 50%;
          display: inline-block;
          &:nth-of-type(2) {
            text-align: right;
          }
        }
        &.item-count {
          color: var(--body-gray);
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .carton-wrap div figure,
  .carton-wrap div img {
    margin: 0;
  }
}
</style>
