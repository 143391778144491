<template>
  <div>
    <review-modal
      :items="modalData"
      v-if="modalData"
      :title="modalTitle"
      :tab-items="tabItemData(modalTitle)"
      :on-click="modalTabSwitch"
    />
    <section id="review" class="container">
      <h2>Review Your Import</h2>
      <p>
        Review your files to make sure that all your data is ready for analysis
      </p>
      <div v-for="typeName in dataTypes" :key="typeName" class="info-details">
        <!-- {{ typeName }}  -->
        <span :class="`icon_${typeName}`">{{ typeName }}</span>
        <ul>
          <li
            v-for="attr in Object.keys(expanded(typeName))"
            :key="`${typeName}_${attr}`"
            :class="`${attr}`"
          >
            <strong>{{ expanded(typeName)[attr] }}</strong>
            <span>{{ attr }}</span>
          </li>
        </ul>
        <base-button @click.native="() => activateModal(typeName)"
          >Review</base-button
        >
      </div>
      <!-- <router-link to="/results" @click.native="completedStep('Review')">
        <base-button>Continue</base-button>
      </router-link> -->
    </section>
    <FooterNav
      :next="`/results`"
      :back="`/rules`"
      :next-on-click="completedStep('Review')"
      :next-active="true"
    />
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import BaseButton from "../components/BaseButton";
import ReviewModal from "../components/ReviewModal";
import FooterNav from "../components/FooterNav";
import { validators } from "../services/validators";
export default {
  components: { BaseButton, ReviewModal, FooterNav },
  name: "Review",
  data() {
    return {
      dataTypes: ["items", "cartons", "orders"],
      modalData: false,
      modalTitle: "",
      filteredData: {},
    };
  },
  beforeMount() {
    const mapped = this.$store.state.orders.mappedColumns;
    const itemMaster = this.$store.state.orders.items;
    // pass data to validators for reference
    validators.setup(mapped, itemMaster);
  },
  mounted() {
    this.$store.commit("udpateActiveStep", { val: "Review" });
  },
  methods: {
    ...mapMutations(["completedStep"]),
    expanded(key) {
      const source = this.storeData[key];
      // const errors = this.$store.state.orders.errors;
      const ready = source.filter((item) => {
        return validators[key](item);
      }).length;
      let returnVal = { ready: 0, errors: 0, total: 0 };
      if (key == "orders") {
        const count = Object.keys(source).length;
        returnVal = {
          total: count,
          ready: ready,
          errors: count - ready,
        };
      } else {
        const count = source.length;
        returnVal = {
          total: count,
          ready: ready,
          errors: count - ready,
        };
      }
      return returnVal;
    },
    activateModal(type) {
      const data = this.storeData[type];
      this.modalData = data;
      this.modalTitle = type;
      this.$store.commit("toggleModal");
    },
    modalTabSwitch(index) {
      // const raw = this.storeData[type];
      const type = this.modalTitle;
      const keys = Object.keys(this.filteredItemData(type));
      const target = keys[index];
      this.modalData = this.filteredItemData(type)[target];
      console.info("switching tab", index);
    },
    tabItemData(type) {
      const rawData = this.storeData[type];
      const validItems = [];
      const invalidItems = [];
      rawData.forEach((item) => {
        if (validators[type](item)) {
          validItems.push(item);
        } else {
          invalidItems.push(item);
        }
      });
      const errorData = {
        title: "Error",
        label: invalidItems.length,
        class: "error",
      };
      const allData = {
        title: "All",
        label: rawData.length,
      };
      const readyData = {
        title: "Ready",
        label: validItems.length,
        class: "success",
      };
      return [allData, readyData, errorData];
    },
    filteredItemData(type) {
      if (this.filteredData[type]) {
        return this.filteredData[type];
      } else {
        const rawData = this.storeData[type];
        const validItems = [];
        const invalidItems = [];
        rawData.forEach((item, index) => {
          item.masterIndex = index;
          if (validators[type](item)) {
            validItems.push(item);
          } else {
            invalidItems.push(item);
          }
        });
        this.filteredData[type] = {
          all: rawData,
          ready: validItems,
          error: invalidItems,
        };
        return this.filteredData[type];
      }
    },
  },
  computed: {
    storeData() {
      const lookup = {
        items: this.$store.state.orders.items,
        cartons: this.$store.state.orders.cartons,
        orders: this.$store.state.orders.orders,
      };
      return lookup;
    },
  },
};
</script>
<style lang="scss">
div.info-details {
  background: #fff;
  border: 1px solid var(--light-gray);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // icon styles
  span.icon_cartons,
  span.icon_orders,
  span.icon_items {
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 600;
    width: 80px;
    text-align: center;
    text-transform: capitalize;
    &::before {
      content: "";
      // background-color: var(--light-gray);
      display: block;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      margin: 0px auto 10px;
    }
  }
  span.icon_items {
    &::before {
      background: var(--light-gray) url("../assets/img/items_icon.svg") 50% 50%
        no-repeat;
    }
  }
  span.icon_cartons {
    &::before {
      background: var(--light-gray) url("../assets/img/carton_icon.svg") 50% 50%
        no-repeat;
    }
  }
  span.icon_orders {
    &::before {
      background: var(--light-gray) url("../assets/img/orders_icon.svg") 50% 50%
        no-repeat;
    }
  }
  // inline list info
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0 1vw;
      padding: 1vw 2vw 1vw 0px;
      border-right: 1px solid var(--light-gray);
      display: inline-block;
      text-align: center;
      font-weight: 600;
      span {
        color: var(--body-gray);
      }
      &:last-of-type {
        border-right: none;
      }
      &.ready {
        strong {
          color: var(--success-drk);
        }
      }
      &.errors {
        strong {
          color: var(--error-drk);
        }
      }
      strong {
        display: block;
        font-size: 2.5rem;
      }
      span {
        text-transform: uppercase;
      }
    }
  }
}
</style>
