export const validators = {
  itemKeys: [],
  itemList: [],
  keyMap: {},
  // pass keymap to be referenced
  setup: function (keys, items) {
    this.keyMap = keys;
    this.itemList = items;
  },
  orders: function (order) {
    const lookup = this.keyMap.Orders;
    // const itemKeys = this.keyMap.Items;
    // let valid = false;
    // const itemRef = lookup["refId"];
    // this.itemList.forEach((item) => {
    //   // will return false if there's nothing in the item master that has the provided refId value
    //   if (item[itemKeys["refId"]] == order[itemRef]) {
    //     valid = true;
    //   }
    // });
    const requiredAttrs = ["refId", "orderId", "quantity"];
    let valid = true;
    requiredAttrs.forEach((attr) => {
      if (typeof order[lookup[attr]] == "undefined") {
        valid = false;
      }
    });
    return valid;
  },
  items: function (item) {
    // check if we have all required data
    const lookup = this.keyMap.Items;
    const requiredAttrs = ["dim-x", "dim-y", "dim-z", "weight"];
    let valid = true;
    requiredAttrs.forEach((attr) => {
      if (typeof item[lookup[attr]] == "undefined") {
        valid = false;
      }
    });
    // return {
    //   valid: validItems,
    //   validCount: validItems.length,
    //   invalid: invalidItems,
    //   invavlidCount: invalidItems.length
    // }
    return valid;
  },
  cartons: function (carton) {
    const lookup = this.keyMap.Cartons;
    const requiredAttrs = ["dim-x", "dim-y", "dim-z", "weightMax"];
    let valid = true;
    requiredAttrs.forEach((attr) => {
      if (typeof carton[lookup[attr]] == "undefined") {
        valid = false;
      }
    });
    return valid;
  },
};
