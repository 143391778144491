// import { sanitizeRefs, itemHandler } from "../services/data-helpers";
const store = {
  state: {
    activeConfig: false,
    activeResponse: false,
    activeOrderId: false,
    activeSheet: "",
    cartons: [],
    items: [],
    orders: [],
    keyMap: {
      items: {
        refId: "",
        name: "",
        sequence: "",
        weight: "",
        dimensions: {
          x: "",
          y: "",
          z: "",
        },
      },
      cartons: {
        refId: "",
        name: "",
        sequence: "",
        price: "",
        dimensions: {
          x: "",
          y: "",
          z: "",
        },
        maxWeight: "",
      },
      orders: {
        refId: "",
        quantity: "",
      },
    },
    userKeys: {
      cartons: [],
      items: [],
      orders: [],
    },
    mappedColumns: {},
    parsedSheets: {},
    itemsRefMap: {},
    cartonsRefMap: {},
    ordersReduced: {},
    errors: [],
    batchResponseItems: [],
    batchComplete: false,
    averages: {},
  },
  mutations: {
    setConfig(state, data) {
      state.activeConfig = Object.assign({}, state.activeConfig, data);
    },
    setActiveSheet(state, data) {
      state.activeSheet = data;
    },
    setResponse(state, data) {
      state.activeResponse = Object.assign({}, state.activeConfig, data);
    },
    setOrder(state, data) {
      state.activeOrderId = data;
    },
    setUserKeys(state, data) {
      state.userKeys[data.target] = data.values;
    },
    setAverages(state, data) {
      state.averages = data;
    },
    setComplete(state, data) {
      state.batchComplete = data;
    },
    pushResponseData(state, data) {
      state.batchResponseItems.push(data);
    },
    joinResponseData(state, data) {
      const newBatchList = state.batchResponseItems.concat(data);
      state.batchResponseItems = newBatchList;
    },
    // updateBatchResults(state, data) {},
    logError(state, data) {
      state.errors.push(data);
    },
    clearErrors(state) {
      state.errors = [];
    },
    updateParsedSheets(state, data) {
      state.parsedSheets = data;
    },
    updateList(state, data) {
      if (
        state[data.target].length == 0 ||
        typeof state[data.target].length === "undefined"
      ) {
        state[data.target] = data.values;
      } else {
        state[data.target] = state[data.target].concat(data.values);
      }
    },
    updateItem(state, data) {
      const arr = state[data.type];
      const index = data.val.masterIndex;
      let targetObj = arr[index];
      console.info("target obj", targetObj, "new Data", data.val);
      const newObj = Object.assign({}, targetObj, data.val);
      targetObj = newObj;
    },
    removeItem(state, data) {
      const target = data.target;
      const index = data.index;
      state[target].splice(index, 1);
    },
    updateKeyMap(state, data) {
      // this object will expand based on what data is passed
      const updated = {};
      // 'items' instead of 'Items'
      const target = data.target.toLowerCase();
      if (target !== "orders" && data.key.indexOf("dim-") === 0) {
        // get which dimension we're looking for by splitting dim-x into ['dim', 'x']
        const dimTarget = data.key.split("-")[1];
        // grab currently assigned dimensions object to use on the empty updated object
        const assignedDims = state.keyMap[target].dimensions;
        // ex: assignedDims['x'] = data.value (likely 'dimensions_x')
        assignedDims[dimTarget] = data.value;
        // add the dimensions object with the new value assigned as an attribute
        updated.dimensions = assignedDims;
      } else {
        updated[data.key] = data.value;
      }
      state.keyMap[target] = Object.assign({}, state.keyMap[target], updated);
    },
    setKeyMap(state, data) {
      // if we have an existing map file, we can reuse it
      state.keyMap = data;
    },
    updateMappedColumns(state, data) {
      // flatten mapped column headers instead of nested dimensions for validation (ex "dim-x":"HEIGHT")
      state.mappedColumns = data;
    },
    mapRef(state, data) {
      const key = data.key;
      const target =
        data.target === "items" ? state.itemsRefMap : state.cartonsRefMap;
      // if we dont have a ref to the unique id, map it to lookup table length index, IE SKU 'ABC':0, 'CDE':1.
      if (typeof target[key] == "undefined") {
        target[key] = Object.keys(target).length;
      }
    },
    clearUserData(state) {
      ["orders", "items", "cartons", "batchResponseItems"].forEach((item) => {
        state[item] = [];
      });
      state.ordersReduced = {};
      state.itemsRefMap = {};
      state.parsedSheets = {};
      state.activeSheet = "";
      state.userKeys = {
        cartons: [],
        items: [],
        orders: [],
      };
      state.keyMap = {
        orders: {
          quantity: "",
          refId: "",
        },
        items: {
          refId: "",
          name: "",
          sequence: "",
          weight: "",
          dimensions: {
            x: "",
            y: "",
            z: "",
          },
        },
        cartons: {
          refId: "",
          name: "",
          sequence: "",
          dimensions: {
            x: "",
            y: "",
            z: "",
          },
          price: "",
          maxWeight: "",
        },
      };
    },
  },
  getters: {
    getOrderById: (state) => (id) => {
      // build request config
      // get refIds for this orderId
      const itemList = state.items;
      const orderList = state.orders;
      const cartonList = state.cartons;
      const cartonKeys = state.keyMap.cartons;
      const itemKeys = state.keyMap.items;
      const orderItems = [];
      // use these keys to normalize against bad column names
      const itemRefKey = state.keyMap.items["refId"] || "refId";
      const orderIdKey = state.keyMap.orders["orderId"] || "orderId";
      const orderRefKey = state.keyMap.orders["refId"] || "refId";
      const orderQuantKey = state.keyMap.orders["quantity"] || "quantity";

      const boxTypes = cartonList.map((carton) => {
        if (carton[cartonKeys["refId"]] !== cartonKeys["refId"]) {
          const cartonCleaned = {
            name: carton[cartonKeys["name"]]
              ? String(carton[cartonKeys["name"]])
              : "",
            sequence: carton[cartonKeys["sequence"]]
              ? String(carton[cartonKeys["sequence"]])
              : "",
            weightMax: Number(carton[cartonKeys["weightMax"]]),
            refId: Number(carton[cartonKeys["refId"]]),
            price: carton[cartonKeys["price"]]
              ? Math.round(carton[cartonKeys["price"]])
              : 0,
            dimensions: {
              x: Number(carton[cartonKeys["dimensions"]["x"]]),
              y: Number(carton[cartonKeys["dimensions"]["y"]]),
              z: Number(carton[cartonKeys["dimensions"]["z"]]),
            },
          };
          return cartonCleaned;
        }
      });
      const cleanItem = (itemObj) => {
        const cleanedItem = {
          refId: itemObj[itemRefKey],
          sequence: itemObj[itemKeys["sequence"]]
            ? String(itemObj[itemKeys["sequence"]])
            : "",
          name: itemObj[itemKeys["name"]],
          weight: Number(itemObj[itemKeys["weight"]]),
          dimensions: {
            x: Number(itemObj[itemKeys["dimensions"]["x"]]),
            y: Number(itemObj[itemKeys["dimensions"]["y"]]),
            z: Number(itemObj[itemKeys["dimensions"]["z"]]),
          },
        };
        if (typeof cleanedItem.refId !== "number") {
          delete cleanedItem.refId;
        }
        return cleanedItem;
      };
      orderList.forEach((order) => {
        if (order[orderIdKey] == id) {
          const item = itemList.find((item) => {
            return item[itemRefKey] == order[orderRefKey];
          });
          if (item && typeof item !== "undefined") {
            let foundItem = Object.assign({}, cleanItem(item));
            foundItem.quantity = Number(order[orderQuantKey]);
            orderItems.push(foundItem);
          } else {
            console.error(
              "order error for id",
              id,
              "couldnt find item",
              order[orderRefKey]
            );
          }
        }
      });
      const config = {
        // before we assign the itemsets, we need to audit the refs
        itemSets: orderItems,
        boxTypes: boxTypes,
      };
      return config;
    },
  },
};
export default store;
