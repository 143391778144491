<template>
  <div
    class="modal-container"
    :class="overlayActive ? 'modal-active' : null"
    v-if="itemObject"
  >
    <div class="modal-inner container">
      <div class="modal-close">
        <base-button class="secondary" @click.native="closeModal"
          >Close</base-button
        >
        <!-- <base-button>Submit Changes</base-button> -->
      </div>
      <h3>{{ title }}</h3>
      <div class="table-wrap">
        <Tabs :items="tabItems" :click-fn="onClick" />
        <base-table>
          <thead>
            <tr>
              <th v-for="label in requiredAttrs[title]" :key="label">
                {{ label }}
              </th>
              <th class="remove-item"><span>&nbsp;</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items.slice(startIndex, endIndex)"
              :key="index"
            >
              <td
                v-for="attr in requiredAttrs[title]"
                :key="`${index}_${attr}`"
                :ref="`${index}_${attr}`"
                @click.self="toggleEditor(index, attr)"
              >
                <span>{{ item[attr] || "" }}</span>
                <div :class="`edit-item item_${index}_${attr}`">
                  <label>{{ attr }}</label>
                  <input
                    :ref="`input_${index}_${attr}`"
                    :placeholder="item[attr]"
                    type="text"
                  />
                  <div class="btn-wrap">
                    <BaseButton
                      @click.native="
                        updateRecord($event, item, attr, `${index}_${attr}`)
                      "
                      >Save</BaseButton
                    >
                    <BaseButton
                      class="secondary"
                      @click.native="closeEditors($event, `${index}_${attr}`)"
                      >Cancel</BaseButton
                    >
                  </div>
                </div>
              </td>
              <td class="remove-item">
                <span @click="removeItem(index)"
                  ><BaseIcon :icon="`close-icon`"
                /></span>
              </td>
            </tr>
          </tbody>
        </base-table>
      </div>
      <Pagination
        :max-pages="5"
        :total-pages="Math.ceil(items.length / this.pageLength)"
        :click="pageUpdate"
      />
    </div>
  </div>
</template>
<script>
import BaseButton from "./BaseButton";
import BaseTable from "./BaseTable";
import BaseIcon from "./BaseIcon";
import Tabs from "./Tabs";
import Pagination from "./Pagination";
export default {
  name: "DataModal",
  props: ["items", "title", "tabItems", "onClick"],
  components: { BaseButton, BaseTable, BaseIcon, Tabs, Pagination },
  data() {
    return {
      validCols: [
        "dimensions_x",
        "dimensions_y",
        "dimensions_z",
        "name",
        "weight",
        "weightMax",
        "refId",
        "weightTare",
        "orderId",
        "quantity",
      ],
      stagedChanges: [],
      filteredItems: [],
      startIndex: 0,
      endIndex: 25,
      pageLength: 25,
      requiredAttrs: {
        items: ["dimensions_x", "dimensions_y", "dimensions_z", "refId"],
        cartons: ["dimensions_x", "dimensions_y", "dimensions_z", "weightMax"],
        orders: ["refId", "quantity", "orderId"],
      },
    };
  },
  methods: {
    updateRecord(event, item, attr, key) {
      if (
        this.$refs[`input_${key}`][0].value &&
        this.$refs[`input_${key}`][0].value.length > 0
      ) {
        let targetRecord = item;
        targetRecord[attr] = this.$refs[`input_${key}`][0].value;
        console.info(targetRecord);
        this.$store.commit("updateItem", {
          type: this.title,
          val: targetRecord,
        });
      }
      this.closeEditors();
    },
    pageUpdate(index) {
      const newIndex = index * this.pageLength;
      this.startIndex = newIndex;
      this.endIndex = newIndex + this.pageLength;
    },
    attrs(item) {
      return Object.keys(item).filter((attr) => {
        return this.validCols.includes(attr);
      });
    },
    toggleEditor(index, attr) {
      // clear active editors
      const target = this.$refs[`${index}_${attr}`][0];
      if (!target.lastChild.classList.contains("show")) {
        this.closeEditors();
        target.lastChild.classList.add("show");
      }
    },
    validateItem(obj) {
      const requiredAttrs = {
        items: ["dimensions_x", "dimensions_y", "dimensions_z", "refId"],
        cartons: ["dimensions_x", "dimensions_y", "dimensions_z", "weightMax"],
        orders: ["refId", "quantity", "orderId"],
      };
      const currentLookup = requiredAttrs[this.title];
      let valid = true;
      currentLookup.forEach((key) => {
        if (
          typeof obj[key] == "undefined" ||
          (obj[key] && obj[key].length == 0)
        ) {
          valid = false;
        }
      });
      return valid;
    },
    closeEditors(event, editor) {
      if (event && editor) {
        const active = this.$refs[`${editor}`][0];
        active.lastChild.classList.remove("show");
        event.target.parentNode.classList.remove("show");
        // console.info(
        //   editor,
        //   active.lastChild.classList,
        //   event.target.parentNode.classList
        // );
        active.lastChild.classList.value = `edit-item ${editor}`;
      } else {
        const activeEditors = document.querySelectorAll("div.edit-item.show");
        if (activeEditors.length > 0) {
          activeEditors.forEach((elem) => elem.classList.remove("show"));
        }
      }
    },
    removeItem(index) {
      // use master index instead 
      if (confirm(`Remove from ${this.title} master?`)) {
        this.$store.commit("removeItem", { target: this.title, index: index });
      }
    },
    closeModal() {
      this.$store.commit("toggleModal");
    },
  },
  computed: {
    overlayActive() {
      return this.$store.state.wrapper.overlayActive;
    },
    parsedData() {
      if (typeof this.items == "object") {
        const array = Object.keys(this.items).map((item) => {
          let formatted = this.items[item];
          formatted.orderId = item;
          return formatted;
        });
        return array;
      } else {
        return this.items;
      }
    },
    itemObject() {
      if (typeof this.items == "undefined") {
        return false;
      } else {
        return this.items;
      }
    },
  },
};
</script>
<style lang="scss">
.modal-container {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(81, 74, 105, 0.2);
  display: none;
  padding: 10vh 0 5vh 0;
  h3 {
    text-transform: capitalize;
  }
  &.modal-active {
    display: block;
  }
  .modal-close {
    float: right;
    font-size: 0.85rem;
    span {
      display: inline-block;
      margin-right: 20px;
    }
  }
  .modal-inner {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    // overflow-y: scroll;
    height: 80vh;
    table {
      tr {
        &:hover {
          .remove-item {
            span {
              opacity: 1;
            }
          }
        }
        th.remove-item {
          display: inline-block;
          height: 1.25rem;
          width: calc(1rem - 10px);
        }
      }
      td {
        border-bottom: 1px solid var(--light-gray);
        font-size: 13px;
        position: relative;
        // span {
        //   display: inline-block;
        //   border: 1px solid transparent;
        // }
        &:hover {
          background: var(--light-gray);
          cursor: pointer;
          // span {
          //   border: 1px dotted var(--body-gray);
          // }
        }
        &.remove-item {
          display: inline-block;
          height: 1rem;
          width: 1rem;
          padding: 10px;
          &:hover {
            background: transparent;
          }
          span {
            opacity: 0;
            svg {
              fill: var(--feedback-error-100);
              transform: scale(3);
            }
          }
        }
        .edit-item {
          position: absolute;
          top: -10px;
          left: -10px;
          display: none;
          background: #ffffff;
          box-shadow: 0px 8px 8px rgba(146, 142, 161, 0.08),
            0px 2px 7px rgba(50, 50, 93, 0.1);
          border-radius: 10px;
          padding: 10px;
          z-index: 300;
          &.show {
            display: inline-block;
          }
          input {
            border: 2px solid var(--pac-purple);
            border-radius: 10px;
            padding: 10px;
          }
          label {
            display: inline-block;
            position: absolute;
            background: #fff;
            color: var(--pac-purple);
            top: 2px;
            padding: 0 5px;
            left: 20px;
          }
          .btn-wrap {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
          }
        }

        &:first-of-type {
          border-left: 1px solid var(--light-gray);
          .edit-item {
            top: -10px;
            right: auto;
            left: 10px;
          }
        }
        &:last-of-type {
          border-right: 1px solid var(--light-gray);
          .edit-item {
            top: -10px;
            right: 10px;
            left: auto;
          }
        }
      }
    }
  }
  .table-wrap {
    max-height: 70vh;
    // overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
  .pagination {
    background: #fbfbff;
    display: flex;
    justify-content: center;
    span {
      border-radius: 5px;
      display: inline-block;
      padding: 4px 8px;
      margin: 8px 2px;
      color: #928ea1;
      font-weight: bold;
      &.active,
      &:hover {
        background: #e5dfff;
        cursor: pointer;
      }
    }
  }
}
</style>
