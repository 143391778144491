<template>
  <div class="pagination">
    <div class="list-wrap">
      <span
        @click="
          () => {
            clickHandler(activeIndex - 1);
          }
        "
        ><ChevronLeft
      /></span>
      <span
        v-for="page in pageArray.slice(pageOffset().start, pageOffset().end)"
        @click="
          () => {
            clickHandler(page);
          }
        "
        :key="page"
        :class="page == activeIndex ? 'active' : ''"
        >{{ page + 1 }}</span
      >
      <span
        v-if="activeIndex < totalPages - 1"
        @click="
          () => {
            clickHandler(activeIndex + 1);
          }
        "
        ><ChevronRight
      /></span>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "./icons/ChevronLeft.vue";
import ChevronRight from "./icons/ChevronRight.vue";
export default {
  components: {
    ChevronLeft,
    ChevronRight,
  },
  name: "Pagination",
  props: {
    totalPages: {
      type: Number,
      default: 1,
    },
    maxPages: {
      type: Number,
      default: 1,
    },
    click: {
      type: Function,
      default: (index) => {
        console.log("CLICKED", index);
      },
    },
  },
  computed: {
    pageArray() {
      const arr = [...Array(this.totalPages).keys()];
      return arr;
    },
  },
  methods: {
    clickHandler(index) {
      if (index >= 0) {
        this.activeIndex = index;
        this.click(index);
      }
    },
    pageOffset() {
      const offset = this.activeIndex + this.maxPages;
      const startVal = this.activeIndex > this.maxPages ? this.activeIndex : 0;
      const offsets = {
        end: offset,
        start: startVal,
      };
      return offsets;
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
};
</script>
<style>
.list-wrap svg {
  fill: #928ea1;
}
</style>
