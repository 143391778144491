<template>
  <div class="progress-status">
    <ul>
      <li>
        <strong>{{ counted }}</strong>
      </li>
      <li>
        {{ label }}
      </li>
      <li :class="uploadStatus">
        {{ counted >= total ? "Complete" : "Uploading" }}
      </li>
    </ul>
    <div
      class="progress-bar"
      :id="`progress-${label}`"
      ref="progWrap"
      :style="percentLoaded"
    >
      <div class="inner">{{ loaded }}%</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UploadProgress",
  props: {
    label: {
      type: String,
      default: "",
    },
    count: {
      type: String,
      default: "0-10",
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      counted: 0,
    };
  },
  computed: {
    calcOffset() {
      const offset =
        this.calcCircumfrence() -
        this.calcCircumfrence() * (this.count / this.total);
      return {
        "stroke-dasharray": this.calcCircumfrence(),
        "stroke-dashoffset": offset,
      };
    },
    loaded() {
      const percent = (this.currentCounted / this.total) * 100;
      return parseInt(percent);
    },
    percentLoaded() {
      const percent = (this.currentCounted / this.total) * 100;
      const gradient = `conic-gradient(rgba(238, 67, 153, 0.8) 0%, rgba(0, 52, 239, 0.8) ${percent}%, var(--light-gray) ${percent}%)`;
      return {
        backgroundImage: gradient,
      };
    },
    currentCounted() {
      return this.counted;
    },
    uploadStatus() {
      let status = "uploading";
      if (this.counted >= this.total) {
        status = "complete";
      }
      return status;
    },
  },
  methods: {
    setCounted(val) {
      this.counted = val;
    },
    updateProgress(percent) {
      const gradient = `conic-gradient(  rgba(0, 52, 239, 0.8) 0%, rgba(238, 67, 153, 0.8) ${percent}%, var(--light-gray) ${percent}%)`;
      try {
        this.$refs["progWrap"].style.setProperty("background-image", gradient);
      } catch (e) {
        console.info(e);
      }
    },
  },
  watch: {
    count: {
      handler(val) {
        const range = val
          .split(" - ")
          .map((item) => {
            return parseInt(item);
          })
          .reduce((prev, current) => {
            if (prev && current) {
              return current - prev;
            } else {
              return current;
            }
          });
        if (range && typeof range === "number") {
          const add = this.currentCounted + range;
          if (add >= this.total) {
            this.setCounted(this.total);
          } else {
            this.setCounted(add);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.progress-status {
  padding: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  /* Shadow */
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  border: 1px solid var(--light-gray);
  display: inline-flex;
  flex-direction: row;
  ul {
    list-style-type: none;
    margin: 0 2rem 0 0;
    padding: 0;
    display: inline-block;
    li {
      strong {
        font-size: 1.4rem;
        display: inline-block;
        margin-bottom: 0.6rem;
      }
      &.complete {
        color: var(--success);
      }
    }
  }
  .progress-bar {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    vertical-align: top;
    svg {
      stroke: var(--pac-purple);
      transform: rotate(-90deg);
      stroke-width: 10px;
      stroke-linecap: round;
      fill-opacity: 0;
      overflow: inherit;
    }
    .gradient-bg {
      clip-path: url(#progressClip);
      background: royalblue;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .inner {
      background: #fff;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      transform: translate(5px, 5px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
