import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import upload from "../views/upload.vue";
import configure from "../views/configure.vue";
import processOrders from "../views/process.vue";
import rules from "../views/rules.vue";
import review from "../views/review.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Upload",
    component: upload,
  },
  {
    path: "/import",
    name: "Import",
    component: upload,
  },
  {
    path: "/configure",
    name: "Configure",
    component: configure,
  },
  {
    path: "/review",
    name: "Review",
    component: review,
  },
  {
    path: "/results",
    name: "Process",
    component: processOrders,
  },
  {
    path: "/rules",
    name: "Rules",
    component: rules,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
