<template>
  <button>
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "BaseButton",
  props: ["click"],
  computed: {
    clickHandler() {
      if (typeof this.click === "undefined") {
        return false;
      } else {
        return this.click;
      }
    },
  },
};
</script>
<style lang="scss">
button {
  font-size: 1em;
  line-height: 2em;
  padding: 5px 30px;
  border-radius: 10px;
  border: 1px solid var(--pac-purple);
  box-shadow: 0px 2px 4px rgba(37, 29, 68, 0.06);
  background: var(--pac-purple);
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s, box-shadow 0.2s, color 0.2s;
  svg {
    fill: #fff;
    // height: 1em;
  }
  &:disabled {
    background: var(--brand-primary-10);
    border-color: var(--brand-primary-10);
    color: var(--brand-primary-20);
  }
  &:hover {
    background: rgba(133, 107, 255, 0.8);
    border-color: var(--pac-purple-light);
    box-shadow: 0px 4px 4px rgba(146, 142, 161, 0.08),
      0px 4px 8px rgba(37, 29, 68, 0.16), 0px 2px 4px rgba(50, 50, 93, 0.1);
  }
  &:active {
    color: var(--brand-primary-20);
  }
  &.success {
    background: var(--feedback-success-80);
    border: 1px solid var(--feedback-success-80);
    box-shadow: 0px 2px 4px rgba(37, 29, 68, 0.06);
    &:hover {
      // box-shadow: 0px 8px 8px rgba(146, 142, 161, 0.08),
      //   0px 4px 8px rgba(37, 29, 68, 0.16), 0px 2px 7px rgba(50, 50, 93, 0.1);
      box-shadow: 0px 4px 4px rgba(146, 142, 161, 0.08),
        0px 4px 8px rgba(37, 29, 68, 0.16), 0px 2px 4px rgba(50, 50, 93, 0.1);
    }
    &:disabled {
      // background: var(--light-gray);
      color: #cdffcd;
      border: 1px solid transparent;
    }
    &:active {
      color: var(--feedback-success-50);
    }
  }
  &.secondary {
    color: var(--pac-blk);
    border: 1px solid var(--light-gray);
    background: #fff;
    &:hover {
      background: var(--light-gray);
      svg {
        fill: var(--pac-blk);
      }
    }
    svg {
      fill: var(--pac-blk);
    }
  }
  &:disabled {
    pointer-events: none;
  }
}
</style>
