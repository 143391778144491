<template>
  <section id="configure" class="container is-fullhd">
    <h1>Match Data Columns</h1>
    <p>Match your spreadsheet columns to our available fields</p>
    <div class="upload-wrap">
      <label class="file-upload">
        Upload Column Map <BaseIcon :icon="`upload-file`" />
        <input
          type="file"
          @change="handleUpload($event)"
          accept="application/JSON,.json,text/x-yaml,application/x-yaml"
        />
      </label>
      <a
        :href="jsonString"
        class="download"
        v-if="storeKeysAssigned()"
        :download="`${sheetName.toLowerCase()}_columns.json`"
      >
        <base-button class="secondary"
          >Download Column Map <BaseIcon :icon="`icon-download`" /></base-button
      ></a>
    </div>
    <div class="columns">
      <div class="column is-half">
        <details
          v-for="(target, index) in toConfig"
          :key="target"
          :ref="`detail_${index}`"
          @click="
            () => {
              activateIndex(index);
            }
          "
        >
          <summary>2.{{ index + 1 }} {{ toConfig[index] }}</summary>
          <form v-on:submit.prevent="confirmFields(index)">
            <fieldset>
              <ul>
                <li>
                  <h4>Paccurate Required Field</h4>
                  <h4>Provided Source Field</h4>
                </li>
                <li v-for="opt in attributes.opts[index]" :key="opt">
                  <label :for="`${toConfig[index]}-${opt}`">{{
                    attrMap[opt]
                  }}</label>
                  <div
                    :class="`select ${selectedClass(
                      mapped[toConfig[index]][opt]
                    )}`"
                  >
                    <select
                      @change="
                        changeOpts($event, `${toConfig[index]}_${opt}`, index)
                      "
                      :class="`attribute-select`"
                      :id="`${toConfig[index]}_${opt}`"
                      :ref="`${toConfig[index]}_${opt}`"
                    >
                      <option value="">Select...</option>
                      <option
                        v-for="val in availableAttributes[toConfig[index]]"
                        :selected="activeOpts(toConfig[index], opt) === val"
                        class="avail"
                        :key="`${opt}-${val}`"
                        :value="val"
                      >
                        {{ trim(val) }} |
                        {{
                          masterLookup[toConfig[index]][0][val]
                            ? trim(masterLookup[toConfig[index]][0][val])
                            : "n/a"
                        }}
                      </option>
                    </select>
                  </div>
                </li>
              </ul>
            </fieldset>
            <!-- <input type="submit" :disabled="!selected" value="Confirm Fields"/> -->
            <base-button class="success" :class-index="activeConfigIndex"
              >Confirm Fields</base-button
            >
            <button v-on:click="undo()" v-if="history.length > 0">Undo</button>
          </form>
        </details>
      </div>
      <div class="column is-half">
        <div class="table-wrap">
          <h3>{{ currentDataType }}</h3>
          <base-table>
            <thead>
              <tr>
                <th
                  v-for="opt in attributes.opts[activeConfigIndex]"
                  :key="opt"
                >
                  {{ opt }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in configuredList" :key="index">
                <td
                  v-for="opt in attributes.opts[activeConfigIndex]"
                  :key="opt"
                >
                  {{ keyMap(row, opt, mapped[toConfig[activeConfigIndex]]) }}
                </td>
              </tr>
            </tbody>
          </base-table>
        </div>
      </div>
      <FooterNav
        :next="`/rules`"
        :back="`/`"
        :next-on-click="completedStep('Match Data')"
        :next-active="configured"
      />
    </div>
  </section>
</template>
<script>
import yaml from "js-yaml";
import { mapMutations } from "vuex";
import BaseButton from "../components/BaseButton";
import BaseTable from "../components/BaseTable";
import BaseIcon from "../components/BaseIcon";
import FooterNav from "../components/FooterNav";
export default {
  components: {
    BaseButton,
    BaseTable,
    FooterNav,
    BaseIcon,
  },
  name: "Configure",
  data() {
    return {
      limit: 100,
      toConfig: ["Items", "Cartons", "Orders"],
      // make this work again
      history: [],
      activeConfigIndex: 0,
      attrMap: {
        "dim-x": "Dimensions X (Height)",
        "dim-y": "Dimensions Y (Width)",
        "dim-z": "Dimensions Z (Length)",
        weight: "Weight value",
        name: "Name value",
        sequence: "Sequence value",
        refId: "RefId value",
        weightMax: "Maximum Weight",
        quantity: "Quantity",
        orderId: "Order Id",
        price: "price",
      },
      attributes: {
        opts: [
          ["dim-x", "dim-y", "dim-z", "weight", "name", "sequence", "refId"],
          ["dim-x", "dim-y", "dim-z", "weightMax", "name", "refId", "price"],
          ["refId", "quantity", "orderId"],
        ],
        currentIndex: 0,
        available: [],
      },
      selectedOpts: {},
      selected: false,
      configured: false,
      configuredList: [],
      mapped: { Items: {}, Cartons: {}, Orders: {} },
    };
  },
  computed: {
    masterLookup() {
      const lookup = {
        Items: this.itemMaster,
        Cartons: this.cartonMaster,
        Orders: this.orderMaster,
      };
      return lookup;
    },
    opts() {
      return this.selectedOpts;
    },
    availableAttributes() {
      const lookup = {
        Items: this.$store.state.orders.userKeys.items,
        Cartons: this.$store.state.orders.userKeys.cartons,
        Orders: this.$store.state.orders.userKeys.orders,
      };
      return lookup;
    },
    currentDataType() {
      return this.toConfig[this.activeConfigIndex];
    },
    itemMaster() {
      return this.$store.state.orders.items.slice(0, 20);
    },
    cartonMaster() {
      return this.$store.state.orders.cartons.slice(0, 20);
    },
    orderMaster() {
      return this.$store.state.orders.orders.slice(0, 20);
    },
    isConfigured() {
      return this.configuredList.length == this.toConfig.length;
    },
    storeKeys() {
      return this.$store.state.orders.keyMap;
    },
    sheetName() {
      return this.$store.state.orders.activeSheet;
    },
    jsonString() {
      const str = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(this.storeKeys)
      )}`;
      return str;
    },
  },
  watch: {
    // this updates as the active type changes along with available columns for each type from the store
    attributes: {
      deep: true,
      handler(val) {
        val.opts.forEach((arr, index) => {
          // use this for refMap
          // ex Items has a specific list of available attributes
          const targ = this.toConfig[index];
          arr.forEach((attr) => {
            // ex val for attr would be dim-x
            // only assign if key is empty
            if (
              this.mapped[targ][attr] == "" ||
              typeof this.mapped[targ][attr] == "undefined"
            ) {
              this.mapped[targ][attr] = this.suggest(
                attr,
                this.availableAttributes[targ]
              );
            }
          });
        });
      },
    },
    opts: {
      deep: true,
      immediate: true,
      handler() {
        // re-filter master
        this.configuredList =
          this.masterLookup[this.toConfig[this.activeConfigIndex]];
      },
    },
    activeConfigIndex: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.configuredList = this.masterLookup[this.toConfig[newVal]];
        }
      },
    },
  },
  mounted() {
    const itemKeys = Object.keys(this.$store.state.orders.items[0]);
    const newAttrs = Object.assign({}, this.attributes, {
      available: itemKeys,
    });
    // check the store to see if we've already done the mapping
    if (
      this.$store.state.wrapper.completedSteps.includes("Match Data") &&
      this.storeKeysAssigned()
    ) {
      // we've been here already, do the mapping of store keys, set the completed state to true
      this.$refs["detail_2"][0].toggleAttribute("open");
      this.mapStoreKeys();
    } else {
      // first time, update the header UI and try to map Items
      this.$store.commit("udpateActiveStep", { val: "Match Data" });
      // open first detail
      this.$refs["detail_0"][0].toggleAttribute("open");
      this.$set(this, "attributes", newAttrs);
    }
  },
  methods: {
    ...mapMutations(["completedStep"]),
    trim(str) {
      if (str.toString().length > 15) {
        return str.toString().substring(0, 7) + "...";
      } else {
        return str;
      }
    },
    storeKeysAssigned() {
      const storeMap = this.$store.state.orders.keyMap;
      const targets = [
        storeMap.items.refId,
        storeMap.cartons.refId,
        storeMap.orders.refId,
      ];
      let assigned = true;
      targets.forEach((target) => {
        if (target === "" && assigned == true) {
          assigned = false;
        }
      });
      return assigned;
    },
    mapStoreKeys() {
      // destructure keys from store to current state
      const storeMap = this.$store.state.orders.keyMap;
      const newMap = {
        Items: {
          "dim-x": storeMap.items.dimensions.x,
          "dim-y": storeMap.items.dimensions.y,
          "dim-z": storeMap.items.dimensions.z,
          name: storeMap.items.name,
          refId: storeMap.items.refId,
          sequence: storeMap.items.sequence,
          weight: storeMap.items.weight,
        },
        Cartons: {
          "dim-x": storeMap.cartons.dimensions.x,
          "dim-y": storeMap.cartons.dimensions.y,
          "dim-z": storeMap.cartons.dimensions.z,
          name: storeMap.cartons.name,
          price: storeMap.cartons.price,
          refId: storeMap.cartons.refId,
          sequence: storeMap.cartons.sequence,
          weightMax: storeMap.cartons.weightMax,
        },
        Orders: {
          refId: storeMap.orders.refId,
          orderId: storeMap.orders.orderId,
          quantity: storeMap.orders.quantity,
        },
      };
      this.$set(this, "mapped", newMap);
      this.activeConfigIndex = 2;
      this.configured = true;
      this.$store.commit("updateMappedColumns", this.mapped);
    },
    activeOpts(target, key) {
      // use keys in store if available. destructure so you don't mutuate the store
      const storeMap = Object.assign(
        {},
        this.$store.state.orders.keyMap[target.toLowerCase()]
      );
      // here is where you'd mutate the store if not using the clone
      if (storeMap.dimensions) {
        Object.keys(storeMap.dimensions).forEach((key) => {
          storeMap[`dim-${key}`] = storeMap.dimensions[key];
        });
      }
      // return the store key if available, otherwise return the mapped val in scope
      if (storeMap[key] && storeMap[key].length > 0) {
        return storeMap[key];
      } else {
        return this.mapped[target][key];
      }
    },
    activateIndex(i) {
      // sets new index, expands the active detail view
      if (this.activeConfigIndex != i) {
        document.querySelectorAll("[open]").forEach((el) => {
          el.removeAttribute("open");
        });
        this.activeConfigIndex = i;
        // this.$set(this, "activeConfigIndex", i);
        this.$refs[`detail_${i}`][0].toggleAttribute("open");
      }
    },
    confirmFields(index) {
      const mapTarget = this.toConfig[index];
      Object.keys(this.mapped[mapTarget]).forEach((key) => {
        const val = this.mapped[mapTarget][key];
        this.confirmField(key, val, mapTarget);
      });
      if (index + 1 <= this.toConfig.length - 1) {
        if (!this.configuredList.includes(index)) {
          this.configuredList.push(index);
        }
        this.activateIndex(index + 1);
      } else {
        // we've assigned the info, lets go to the process screen
        this.configured = true;
      }
    },
    changeOpts(e, opt, i) {
      const list = this.$refs[opt][0].parentElement.classList;
      const optVal = opt.split("_")[1];
      if (e.target.value.length > 0 && !list.contains("selected")) {
        this.$refs[opt][0].parentElement.classList.add("selected");
      }
      if (e.target.value.length == 0 && list.contains("selected")) {
        this.$refs[opt][0].parentElement.classList.remove("selected");
      }
      this.setSuggested(optVal, e.target.value, i);
    },
    selectedClass(val) {
      if (typeof val != "undefined" && val !== "") {
        return "selected";
      } else {
        return "";
      }
    },
    sidebarClass(index) {
      if (index == this.activeConfigIndex) {
        return "current";
      }
      if (
        index < this.activeConfigIndex ||
        this.configuredList.includes(index)
      ) {
        return "active";
      }
    },
    keyMap(item, opt, obj) {
      // find the key in the provided object, then return the value of the key in the item object
      // if there is obj['dim-x'] and it is something like 'dimensions_x', return item['dimensions_x']
      if (obj[opt]) {
        return item[obj[opt]];
      }
    },
    confirmField(field, value, t) {
      //
      const val = value || this.selected;
      const target = t || this.toConfig[this.activeConfigIndex];

      // const activeAttrArray = this.attributes.opts[this.activeConfigIndex];
      // push this to history in case we need to undo
      // this.history.push({target:this.toConfig[this.activeConfigIndex], key:field, val:val, index:this.attributes.currentIndex})
      this.$store.commit("updateKeyMap", {
        target: target,
        key: field,
        value: val,
      });
      if (typeof this.mapped[target] == "undefined") {
        this.mapped[target] = {};
        this.mapped[target][field] = value;
      } else {
        this.mapped[target][field] = value;
      }
      // push flattened lookup to store
      this.$store.commit("updateMappedColumns", this.mapped);
      this.selectedOpts = {};
    },
    setSuggested(key, val, index) {
      const current = this.toConfig[index];
      const newAttr = {};
      newAttr[key] = val;
      const newOpts = Object.assign({}, this.mapped[current], newAttr);
      // selectedOpts is has a computed property with a watcher to update the right hand side
      this.selectedOpts = newOpts;
      this.mapped[current] = newOpts;
    },
    suggest(key, array) {
      // dimensions, ex dim-x
      let suggested;
      // const target = this.mapped[this.currentDataType];
      // console.info(this.mapped, target, array);
      if (key) {
        const substrings = key.split("-");
        array.forEach((str) => {
          let match = false;
          let matchedStrings = 0;
          substrings.forEach((substring) => {
            // if any of the substrings aren't in the source, it doesn't match
            if (str.includes(substring)) {
              matchedStrings++;
            }
            if (matchedStrings === substrings.length) {
              match = true;
            }
          });
          // if all substrings are present, this is the right string, ie dimensions_x would match dim-x
          if (match) {
            suggested = str;
          }
        });
        return suggested;
      } else {
        return false;
      }
    },
    undo() {
      const obj = this.history.pop();
      const activeAttrArray = this.attributes.opts[this.activeConfigIndex];
      const avail = this.attributes.available;
      // update the store
      this.$store.commit("updateKeyMap", {
        target: obj.target,
        key: obj.key,
        value: "",
      });
      activeAttrArray.push(obj.key);
      avail.push(obj.val);
      this.attributes = Object.assign({}, this.attributes, {
        currentIndex: this.attributes.currentIndex - 1,
      });
    },
    complete(step) {
      this.$store.commit("completedStep", { val: step });
    },
    handleUpload(e) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onload = this.parseYAML;
      reader.readAsText(file);
    },
    parseYAML(e) {
      try {
        const parsedMap = yaml.load(e.target.result, "utf-8", { json: true });
        // set the keys in the store
        this.$store.commit("setKeyMap", parsedMap);
        // close any open details
        document.querySelectorAll("details[open]").forEach((elem) => {
          elem.toggleAttribute("open");
        });
        this.$refs["detail_2"][0].toggleAttribute("open");
        this.mapStoreKeys();
      } catch (e) {
        console.error("PARSE ERROR", e);
      }
    },
  },
};
</script>
<style lang="scss">
.back-btn {
  position: relative;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(37, 29, 68, 0.06);
  border-radius: 4px;
  width: 30px;
  height: 40px;
  padding: 8px 10px;
  float: left;
  transform: translateX(-200%);
  margin-right: -60px;
  span {
    visibility: hidden;
  }
  &::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    border: 1px solid var(--body-gray);
    border-top: none;
    border-right: none;
    transform: rotate(45deg);
    left: 5px;
    top: 5px;
    left: 20px;
    top: 17px;
  }
  &:hover {
    &::after {
      border-color: var(--pac-purple);
    }
  }
}
#configure {
  div {
    vertical-align: top;
  }
  h1 {
    margin-bottom: 0px;
  }
  p {
    margin-top: 0px;
  }
  .table-wrap {
    background: #fff;
    border: 1px solid var(--light-gray);
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 30px;
    th {
      font-size: 0.6rem;
    }
    td,
    th {
      // padding: 5px 8px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  details {
    background: #fff;
    // border: 1px solid var(--light-gray);
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 10px;
    fieldset {
      border: 0;
      padding: 0;
    }
    summary {
      font-weight: bold;
    }
  }
  .progress-nav ul {
    // padding: 30px 0 30px 30px;
    li {
      color: var(--body-gray);
      position: relative;
      transition: color 0.3s;

      &:hover {
        cursor: pointer;
        color: var(--pac-blk);
      }
      &.active {
        .success {
          color: #00c17c;
        }
        // strong {
        //   color: var(--pac-blk);
        // }
      }
      &.current {
        span {
          color: var(--pac-purple);
        }
        strong {
          color: var(--pac-blk);
        }
        &::after {
          content: "";
          width: 3px;
          height: 100%;
          background: var(--pac-purple);
          // border: 1px solid var(--pac-purple);
          position: absolute;
          right: 0px;
          top: 0;
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      margin-bottom: 1rem;
      h4 {
        color: #928ea1;
        display: inline-block;
        // &:first-of-type {
        //   padding: 0 2vw 0 0;
        //   min-width: calc(50% - 80px);
        //   margin-right: 80px;
        //   position: relative;
        // }
        // &:last-of-type {
        //   padding: 0 5vw 0 0;
        // }
        width: 50%;
      }
      label {
        background: rgba(182, 166, 255, 0.2);
        border-radius: 4px;
        display: inline-block;
        color: var(--pac-purple);
        font-weight: bold;
        font-size: 0.8rem;
        line-height: 3rem;
        padding: 0 10px;
        width: calc(50% - 50px);
        margin-right: 50px;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 15px;
          height: 15px;
          border: 1px solid var(--body-gray);
          border-top: transparent;
          border-left: transparent;
          transform: rotate(-45deg);
          position: absolute;
          right: -30px;
          top: 15px;
        }
      }
      select {
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: 1px solid var(--light-gray);
        padding: 0 1em 0 0;
        margin: 0;
        // width: 100%;
        font-family: inherit;
        font-size: 0.8rem;
        line-height: 3rem;
        cursor: inherit;
        outline: var(--light-gray);
        // font-weight: bold;
        padding: 0 10px;
        border-radius: 4px;
        // box-shadow: 0px 2px 4px rgba(133, 107, 255, 0.06),
        //   0px 8px 16px rgba(133, 107, 255, 0.06);
        // option.avail{
        //   font-weight: bold;
        // }
        &:focus {
          outline: var(--pac-purple);
          border: 1px solid var(--pac-purple);
        }
      }
      .select {
        display: inline-block;
        position: relative;
        width: calc(50% - 30px);
        &.selected {
          &::after {
            border-color: var(--success);
            width: 3px;
          }
        }
        &::after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border: 1px solid var(--pac-blk);
          border-top: transparent;
          border-left: transparent;
          transform: rotate(45deg);
          position: absolute;
          right: 12px;
          top: 20px;
        }
      }
    }
  }
  .columns {
    width: 100%;
  }
  .upload-wrap {
    margin-bottom: 10px;
  }
  a.download {
    float: right;
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    svg {
      margin-top: 4px;
      display: inline-block;
    }
  }
}
</style>
