<template>
  <div
    v-html="require(`!html-loader!../assets/img/${icon}.svg`)"
    :class="`svg-container ${icon}`"
  ></div>
</template>
<script>
export default {
  name: "BaseIcon",
  props: {
    icon: {
      Type: String,
      default: "carton_icon",
    },
  },
  mounted() {
    if (
      this.$el.firstElementChild &&
      this.$el.firstElementChild.nodeName === "svg"
    ) {
      const svgElement = this.$el.firstElementChild;
      // use `viewBox` attribute to get the svg's inherent width and height
      const viewBox = svgElement
        .getAttribute("viewBox")
        .split(" ")
        .map((n) => Number(n));
      const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2);
      // set width and height relative to font size
      // if growByHeight is true, height set to 1em else width set to 1em and remaining is calculated based on widthToHeight ratio
      if (this.growByHeight) {
        svgElement.setAttribute("height", "1em");
        svgElement.setAttribute("width", `${widthToHeight}em`);
      } else {
        svgElement.setAttribute("width", "1em");
        svgElement.setAttribute("height", `${1 / widthToHeight}em`);
      }
      svgElement.classList.add("svg-class");
    }
  },
};
</script>
<style lang="scss">
.svg-container {
  display: inline-flex;
  overflow: hidden;
  svg {
    fill: currentColor;
  }
}
</style>
