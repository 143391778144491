<template>
  <header>
    <router-link to="/"
      ><img src="../assets/paccurate_final.svg"
    /></router-link>
    <nav>
      <ul>
        <li
          v-for="(item, index) in navItems"
          :class="activeClass(item)"
          @click="navigate(index)"
          :key="item"
        >
          <span v-if="!completed.includes(item)" :class="`step-${index + 1}`">{{
            index + 1
          }}</span
          ><span v-if="completed.includes(item)" :class="`step-${index + 1}`"
            >&nbsp;</span
          >
          {{ item }}
        </li>
      </ul>
    </nav>
  </header>
</template>
<script>
export default {
  name: "AppHeader",
  data() {
    return {
      navItems: ["Import Data", "Match Data", "Add Rules", "Review", "Results"],
      routes: ["import", "configure", "rules", "review", "results"],
    };
  },
  computed: {
    completed() {
      return this.$store.state.wrapper.completedSteps;
    },
    active() {
      return this.$store.state.wrapper.activeStep;
    },
  },
  methods: {
    activeClass(string) {
      if (this.active == string) {
        return "current";
      }
      if (this.completed.includes(string)) {
        return "complete";
      } else {
        return "locked";
      }
    },
    navigate(index) {
      console.info(this.$route);
      const destination = this.routes[index];
      this.$store.commit("udpateActiveStep", { val: this.navItems[index] });
      this.$router.push({ path: destination });
    },
  },
};
</script>
<style lang="scss">
header {
  height: 80px;
  background: #fff;
  // border-bottom: 1px solid;
  box-shadow: 0px 3px 44px -16px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  img {
    max-height: calc(100% - 40px);
    padding: 20px;
    height: 40px;
  }
  nav {
    --itemPad: 2vw;
    display: inline-flex;
    a {
      display: inline-block;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        border-right: 1px solid var(--light-gray);
        padding: 30px var(--itemPad) 30px 0;
        font-family: "Neue Haas Grotesk Display Pro Bold", sans-serif;
        font-weight: bold;
        position: relative;
        transition: color 0.2s;
        color: var(--pac-blk);
        &:last-of-type {
          border-right: 0;
        }
        span {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid var(--pac-purple);
          color: var(--pac-purple);
          display: inline-block;
          margin: 0 var(--itemPad);
          display: inline-block;
          text-align: center;
          line-height: 21px;
          transition: background-color 0.2s, color 0.2s;
        }
        &::after {
          content: "";
          background-color: var(--pac-purple);
          height: 0px;
          width: 100%;
          position: absolute;
          bottom: 0px;
          transition: height 0.2s, background-color 0.2s, color 0.2s;
          height: 0px;
          left: 0px;
        }
        &.locked {
          pointer-events: none;
          color: var(--body-gray);
        }
        &.current,
        &:hover {
          // font-family: "Neue Haas Grotesk Display Pro Bold", sans-serif;
          color: var(--pac-purple);
          cursor: pointer;
          span {
            background-color: var(--pac-purple-light);
            color: #fff;
          }
          &::after {
            height: 2px;
          }
        }
        &.complete {
          span {
            background-image: url("../assets/img/check_icon.svg");
            background-repeat: no-repeat;
            background-size: 60% 60%;
            background-position: 50% 50%;
            background-color: var(--success);
            border-color: var(--success);
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  header nav ul li {
    padding: 30px var(--itemPad);
    span {
      display: none;
    }
  }
}
</style>
