// import Vuex from 'vuex'
import rules from "./rules";
import orders from "./orders";
import wrapper from "./wrapper";
// import Vue from 'vue'
const store = {
  modules: {
    rules: rules,
    orders: orders,
    wrapper: wrapper,
  },
};
export default store;
