<template>
  <div class="tabs-wrap">
    <div
      :class="`tab-wrap ${item.class ? item.class : ''} ${
        index == activeTabIndex ? 'active' : ''
      }`"
      @click="
        () => {
          clickHandler(index);
        }
      "
      v-for="(item, index) in items"
      :key="index"
    >
      {{ item.title }}<span>{{ item.label }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tabs",
  props: {
    items: {
      Type: Array,
      default: [
        { title: "Tab 1", label: "2", class: "success" },
        { title: "Tab 2", label: "1", class: "error" },
      ],
    },
    clickFn: {
      Type: Function,
      default: (index) => {
        console.info(`click ${index}`);
      },
    },
  },
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  methods: {
    clickHandler(index) {
      this.activeTabIndex = index;
      this.clickFn(index);
    },
  },
};
</script>
<style lang="scss">
.tabs-wrap {
  border-bottom: 1px solid var(--brand-dark-purple-30);
  margin-bottom: 1rem;
  font-size: 0.8rem;
  .tab-wrap {
    display: inline-block;
    text-transform: uppercase;
    color: var(--brand-dark-purple-50);
    margin-right: 2rem;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    padding: 0 0 0.5rem 0;
    &.active,
    &:hover {
      color: var(--brand-primary-100);
      border-color: var(--brand-primary-100);
    }
    &:hover {
      cursor: pointer;
    }
    &.success {
      span {
        background: var(--feedback-success-20);
        color: var(--feedback-success-100);
      }
    }
    &.error {
      span {
        background: var(--feedback-error-50);
        color: var(--feedback-error-100);
      }
    }
    &.inactive {
      span {
        background: var(--ui-grey-50);
        color: var(--brand-dark-purple-80);
      }
    }
    span {
      display: inline-block;
      background: var(--brand-primary-20);
      color: var(--brand-primary-100);
      margin-left: 1rem;
      border-radius: 8px;
      padding: 0.25rem 0.5rem;
    }
  }
}
</style>
