const wrapper = {
  state: {
    activeStep: "Import Data",
    completedSteps: [],
    overlayActive: false,
    activeFile: "",
  },
  mutations: {
    udpateActiveStep(state, obj) {
      state.activeStep = obj.val;
    },
    completedStep(state, val) {
      if (!state.completedSteps.includes(val)) {
        state.completedSteps.push(val);
      }
    },
    removeStep(state) {
      const index = state.completedSteps.length - 1;
      state.completedSteps.splice(index, 1);
    },
    toggleModal(state) {
      state.overlayActive = !state.overlayActive;
    },
    setFileName(state, val) {
      state.activeFile = val;
    },
  },
};
export default wrapper;
