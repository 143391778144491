<template>
  <nav id="footer-nav">
    <div class="container">
      <router-link v-if="!hideBack" :to="back"
        ><base-button :disabled="!backActive" class="secondary"
          >Go Back</base-button
        ></router-link
      >
      <router-link v-if="!hideNext" :to="next"
        ><base-button
          @click="nextOnClick"
          :disabled="!nextActive"
          class="next-btn"
          >Continue</base-button
        ></router-link
      >
    </div>
  </nav>
</template>
<script>
import BaseButton from "./BaseButton";
export default {
  name: "FooterNav",
  components: {
    BaseButton,
  },
  props: {
    next: {
      Type: String,
      default: "/",
    },
    back: {
      Type: String,
      default: "/",
    },
    nextActive: {
      Type: Boolean,
      default: false,
    },
    nextOnClick: {
      Type: Function,
      default() {
        return () => {
          console.info("click");
        };
      },
    },
    backActive: {
      Type: Boolean,
      default: true,
    },
    hideBack: {
      Type: Boolean,
      default: false,
    },
    hideNext: {
      Type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
#footer-nav {
  .next-btn {
    float: right;
  }
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 90;
  width: calc(100% - 4rem);
  padding: 2rem;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    var(--background),
    var(--background)
  );
  button {
    border-radius: 10px;
  }
}
</style>
