import { packConfig } from "./packer";
export const containsAll = (source, ref) => {
  let contains = false;
  ref.forEach((item) => {
    contains = source.includes(item);
  });
  return contains;
};
export const suggest = (key, array) => {
  // dimensions, ex dim-x
  const substrings = key.split("-");
  let suggested;
  array.forEach((str) => {
    let match = false;
    let matchedStrings = 0;
    substrings.forEach((substring) => {
      // if any of the substrings aren't in the source, it doesn't match
      if (str.includes(substring)) {
        matchedStrings++;
      }
      if (matchedStrings === substrings.length) {
        match = true;
      }
    });
    // if all substrings are present, this is the right string
    if (match) {
      suggested = str;
    }
  });
  return suggested;
};

export const findLineItem = (order, responseList, store) => {
  const id = order.orderId;
  const targetOrder = responseList.filter((item) => {
    return item.orderId.length > 0 && item.orderId === id;
  })[0];
  if (typeof targetOrder !== "undefined") {
    const boxList = targetOrder.packData.boxes;
    const boxManifest = {};
    const boxNames = targetOrder.packData.boxes.map((box) => {
      return { name: box.box.name, id: box.box.id };
    });
    // find the box(es) that contain the sku we're looking for
    boxList.forEach((box) => {
      const findBoxIndex = (id, name) => {
        let boxindex = "";
        // how many of this box name are there
        let instances = boxNames.filter((box) => {
          return box.name == name;
        });
        // find the one with the id that matches
        instances.forEach((boxObj, index) => {
          if (boxObj.id == id && instances.length > 1) {
            boxindex = `-${index + 1}`;
          }
        });
        return boxindex;
      };
      const boxName = box.box.name + findBoxIndex(box.box.id, box.box.name);
      box.box.items.forEach((item) => {
        // found the item in this box
        if (item.item.name.includes(order.refId)) {
          if (typeof boxManifest[boxName] === "undefined") {
            boxManifest[boxName] = 1;
          } else {
            boxManifest[boxName]++;
          }
        }
      });
    });
    // return line items for each order with each box assigned
    const returnedVals = [];
    Object.keys(boxManifest).forEach((boxName) => {
      const boxId = boxName;
      if (boxManifest[boxName] > 0) {
        const orderObj = Object.assign({}, order, {
          quantity: boxManifest[boxName],
          LoadNumber: boxId,
        });
        returnedVals.push(orderObj);
      }
    });
    return returnedVals;
  } else {
    store.commit("logError", {
      error: "item lookup",
      message: `could not find item data for order ${id}`,
    });
    return false;
  }
};
export const sanitizeRefs = (items) => {
  const itemList = items.map((itemObj) => {
    // DONT MUTATE THE MASTER
    let item = Object.assign({}, itemObj);
    if (item.refId && typeof item.refId !== "number") {
      // if non-integer, pass the refId as name and then delete the refId
      item.name = item.name ? item.refId + " " + item.name : item.refId;
      delete item.refId;
    } else {
      item.name = item.name ? item.refId + " " + item.name : item.refId;
    }
    return item;
  });
  return itemList;
};
export const itemHandler = (val, type, map) => {
  // this should be passed $store.state.keyMap[type]
  const refMap = map;
  const stringAttrs = ["name", "sequence"];
  const floatAttrs = ["length", "width", "height", "weight", "weightMax"];
  const numAttrs = ["price", "refId"];
  Object.keys(val).forEach((key) => {
    if (floatAttrs.includes(key)) {
      val[key] = parseFloat(val[key]);
    }
    if (numAttrs.includes(key)) {
      val[key] = parseInt(val[key]);
    }
    if (stringAttrs.includes(key)) {
      val[key] = String(val[key]);
    }
  });
  const dims = {
    x: parseFloat(val[refMap.dimensions.x]),
    y: parseFloat(val[refMap.dimensions.y]),
    z: parseFloat(val[refMap.dimensions.z]),
  };
  val.dimensions = dims;
  return val;
};
export const loadConfig = (id, store, list) => {
  // build request config
  // get refIds for this orderId
  const itemList = store.state.items;
  const cartonList = store.state.cartons;
  const itemsLookup = {};
  if (list && list[id]) {
    list[id].forEach((item) => {
      itemsLookup[item.refId] = item;
    });
    const orderItems = itemList
      .filter((item) => {
        item.name = item.name ? item.name : "";
        const exists = (orderItem) => {
          return (
            (orderItem.refId &&
              Object.keys(itemsLookup).includes(orderItem.refId.toString())) ||
            Object.keys(itemsLookup).includes(
              orderItem.name.split(" ")[0].toString()
            )
          );
        };
        return exists(item);
      })
      .map((item) => {
        const lookup = item.refId ? item.refId : item.name.split(" ")[0];
        item.quantity = parseInt(itemsLookup[lookup].quantity);
        return item;
      });
    //
    if (orderItems.length === 0) {
      store.commit("logError", {
        error: "order error",
        message: `Order ${id}: could not find item data for ${Object.keys(
          itemsLookup
        ).join(", ")}. Please update xls file and re-upload`,
      });
    }
    const config = {
      boxTypes: cartonList.map((item) => {
        return itemHandler(item, "cartons", store.state.keyMap["cartons"]);
      }),
      // before we assign the itemsets, we need to audit the refs
      itemSets: sanitizeRefs(orderItems).map((item) => {
        return itemHandler(item, "items", store.state.keyMap["items"]);
      }),
    };
    return config;
  } else {
    return { itemSets: [] };
  }
};
// let processingBatch = false;
export const cancelBatch = () => {
  const controller = new AbortController();
  controller.abort();
};
export const processBatch = (list, store, callback) => {
  const length = list.length;
  if (length < 100) {
    // small batch processing
    for (let i = 0; i < length; i++) {
      const orderData = loadConfig(list[i], store, store.state.ordersReduced);
      if (orderData.itemSets.length > 0) {
        // const store = this.$store
        orderData.boxTypeChoiceGoal = "most-items";
        orderData.itemInitialOrientationPreferred = true;
        orderData.itemOrientationSearchDepth = 2;
        packConfig(orderData)
          .then((response) => {
            store.commit("pushResponseData", {
              orderId: list[i],
              packData: response.data,
            });
            if (i === length - 1) {
              // this.processing = false
              // this.completed = true
              callback();
            }
          })
          .catch((err) => {
            store.commit("logError", {
              error: `API Error ${err.response.data.code}`,
              message: `${err.response.data.message}`,
            });
          });
      }
    }
  } else {
    // const count = Math.ceil(length/1000)
    for (let i = 1; i < length; i++) {
      // give orders more time because they need to be compressed
      const timeOut = i * 200;
      // let batchIndex = this.batchItemIndex
      setTimeout(() => {
        const orderData = loadConfig(list[i], store, store.state.ordersReduced);
        if (orderData.itemSets.length > 0) {
          // const store = this.$store
          orderData.boxTypeChoiceGoal = "most-items";
          orderData.itemInitialOrientationPreferred = false;
          orderData.itemOrientationSearchDepth = 2;
          packConfig(orderData).then((response) => {
            store.commit("pushResponseData", {
              orderId: list[i],
              packData: response.data,
            });
          });
          if (i === length - 1) {
            // this.processing = false
            // this.completed = true
            callback();
          }
        }
      }, timeOut);
    }
  }
};
// calculate averages based on batch response data
// use these methods to SET in store and then render on results
const normalized = (orderObj) => {
  let clear = {};
  if (orderObj.packData && typeof orderObj.packData !== "undefined") {
    clear = orderObj;
  } else {
    clear = {
      packData: orderObj,
    };
  }
  return clear;
};
export const newAverages = (latest, storeAvgs) => {
  // pass latest 128 to new averages method
  const batchAvgs = batchAverages(latest);
  let newAvgs = {};
  Object.keys(batchAvgs).map(function (k) {
    if (k == "total" || k == "totalCost") {
      newAvgs[k] = Number(batchAvgs[k]) + Number(storeAvgs[k]);
    } else {
      newAvgs[k] =
        (batchAvgs[k] * batchAvgs["total"] +
          storeAvgs[k] * storeAvgs["total"]) /
        (batchAvgs["total"] + storeAvgs["total"]);
    }
  });
  return newAvgs;
};
export const itemAverages = (list) => {
  // name, quantity, deltaCost
  const lookup = {};
  list.forEach((item) => {
    if (typeof lookup[item.name] == "undefined") {
      lookup[item.name] = {
        quantity: 1,
        cost: item.deltaCost,
      };
    } else {
      const target = lookup[item.name];
      target.quantity++;
      target.cost += item.deltaCost;
    }
  });
  const avgs = Object.keys(lookup)
    .map((item) => {
      const itemCount = lookup[item].quantity;
      const itemAvg = { name: item, count: itemCount };
      Object.keys(lookup[item]).map((key) => {
        itemAvg[key] = lookup[item][key] / itemCount;
      });
      delete itemAvg.quantity;
      return itemAvg;
    })
    .sort((a, b) => {
      return a.cost < b.cost;
    });
  return avgs;
};
export const cartonAverages = (list) => {
  // name, item count, volume use, price, netWeight
  const lookup = {};
  // const total = list.length;
  // tally totals
  list.forEach((carton) => {
    if (typeof lookup[carton.name] == "undefined") {
      lookup[carton.name] = {
        itemCount: carton.itemCount,
        cost: carton.cost,
        volumeUtilization: carton.volumeUtilization,
        netWeight: carton.netWeight,
        useCount: 1,
      };
    } else {
      const target = lookup[carton.name];
      target.itemCount += carton.itemCount;
      target.cost += carton.cost;
      target.volumeUtilization += carton.volumeUtilization;
      target.netWeight += carton.netWeight;
      target.useCount++;
    }
  });
  // divide totals by count, return array of boxes with names and averages
  const avgs = Object.keys(lookup)
    .map((box) => {
      const boxCount = lookup[box].useCount;
      const boxAvg = { name: box, used: lookup[box].useCount };
      Object.keys(lookup[box]).map((key) => {
        boxAvg[key] = lookup[box][key] / boxCount;
      });
      delete boxAvg.useCount;
      return boxAvg;
    })
    .sort((a, b) => {
      return a.cost < b.cost;
    });
  // return the averages array
  return avgs;
};
export const batchAverages = (raw) => {
  if (raw && raw.length > 0) {
    const avgPrice =
      raw
        .map((order) => {
          order = normalized(order);
          const data = order.packData;
          const price = data.totalCost ? data.totalCost : 0;
          return Number(price);
        })
        .reduce((prev, current) => prev + current) / raw.length;
    // num cartons
    const avgCartons =
      raw
        .map((order) => {
          order = normalized(order);
          const data = order.packData;
          const boxes = data.lenBoxes ? data.lenBoxes : 0;
          return Number(boxes);
        })
        .reduce((prev, current) => prev + current) / raw.length;
    const avgItems =
      raw
        .map((order) => {
          order = normalized(order);
          const data = order.packData;
          const boxes = data.lenItems ? data.lenItems : 0;
          return Number(boxes);
        })
        .reduce((prev, current) => prev + current) / raw.length;
    const avgVolume =
      raw
        .map((order) => {
          order = normalized(order);
          const data = order.packData;
          const volumne = data.volumeUtilization
            ? data.volumeUtilization.toFixed(2)
            : 0;
          return Number(volumne);
        })
        .reduce((prev, current) => prev + current) / raw.length;
    const avgWeight =
      raw
        .map((order) => {
          order = normalized(order);
          const data = order.packData;
          const volumne = data.totalWeight ? data.totalWeight.toFixed(2) : 0;
          return Number(volumne);
        })
        .reduce((prev, current) => prev + current) / raw.length;
    const totalCost = raw
      .map((order) => {
        order = normalized(order);
        const data = order.packData;
        const cost = data.totalCost ? Number(data.totalCost) : 0;
        return cost;
      })
      .reduce((prev, current) => Number(prev + current));
    const avgObj = {
      total: raw.length,
      totalCost: totalCost.toFixed(2),
      cost: avgPrice.toFixed(2),
      cartons: avgCartons.toFixed(2),
      items: avgItems.toFixed(2),
      volume: (100 * avgVolume).toFixed(2),
      weight: avgWeight.toFixed(2),
    };
    return avgObj;
  } else {
    const avgObj = {
      total: raw.length,
      cost: "PROCESSING",
      cartons: "PROCESSING",
      items: "PROCESSING",
      volume: "PROCESSING",
    };

    return avgObj;
  }
};
