import axios from "axios";
export const packConfig = async (opts) => {
  const uri = "https://cloud.api.paccurate.io/";
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `apikey ${process.env.VUE_APP_PACCURATE_API_KEY}`,
    },
  };
  return await axios.post(uri, opts, config);
};
export const packBatch = async (opts) => {
  const uri = "https://manage.staging.paccurate.io/requests/batch";
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `apikey ${process.env.VUE_APP_PACCURATE_API_KEY}`,
    },
  };
  return await axios.post(uri, opts, config);
};
