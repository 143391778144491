<template>
  <section id="rules" class="container">
    <h1>Add Rules & Options</h1>
    <div class="upload-wrap">
      <label class="file-upload">
        Upload Configuration <BaseIcon :icon="`upload-file`" />
        <input
          type="file"
          @change="handleUpload($event)"
          accept="application/JSON,.json,text/x-yaml,application/x-yaml"
        />
      </label>
      <a :href="jsonString" class="download" download="batch-config.json">
        <base-button class="secondary"
          >Download Config <BaseIcon :icon="`icon-download`" /></base-button
      ></a>
      <textarea @input="debounceRaw" v-model="rawConfig"></textarea>
    </div>
    <FooterNav
      :next="`/review`"
      :back="`/configure`"
      :next-on-click="completedStep('Add Rules')"
      :next-active="true"
    />
  </section>
</template>
<script>
import yaml from "js-yaml";
import { mapMutations } from "vuex";
const _ = require("lodash");
import BaseButton from "../components/BaseButton";
import FooterNav from "../components/FooterNav";
import BaseIcon from "../components/BaseIcon";
export default {
  components: {
    BaseButton,
    FooterNav,
    BaseIcon,
  },
  name: "Rules",
  data() {
    return {
      rawConfig: `{ "rules": [] }`,
    };
  },
  computed: {
    packMerge() {
      return this.$store.state.rules.rulesConfig;
    },
    jsonString() {
      const str = `data:text/json;charset=utf-8,${encodeURIComponent(
        this.rawConfig
      )}`;
      return str;
    },
  },
  mounted() {
    this.$store.commit("udpateActiveStep", { val: "Add Rules" });
    if (Object.keys(this.packMerge).length > 0) {
      this.rawConfig = JSON.stringify(this.packMerge, null, 2);
    }
  },
  methods: {
    ...mapMutations(["completedStep"]),
    handleUpload(e) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onload = this.parseYAML;
      reader.readAsText(file);
      // console.info(file)
    },
    parseYAML(e) {
      try {
        let newConfig = {};
        const parsedYML = yaml.load(e.target.result, "utf-8", { json: true });
        if (typeof parsedYML["pack_merge"] !== "undefined") {
          newConfig = parsedYML["pack_merge"];
        } else {
          newConfig = parsedYML;
        }
        this.rawConfig = JSON.stringify(newConfig, null, 2);
        this.$store.commit("setRules", newConfig);
      } catch (e) {
        console.error("PARSE ERROR", e);
      }
    },
    debounceRaw: _.debounce(function (e) {
      try {
        const updatedConfig = JSON.parse(e.target.value);
        this.rawConfig = JSON.stringify(updatedConfig, null, 2);
        this.$store.commit("setRules", updatedConfig);
      } catch (err) {
        console.error("Invalid JSON", err);
      }
    }, 300),
  },
};
</script>
<style lang="scss">
#rules {
  textarea {
    background: #f2f2f2;
    font-family: monospace;
    border: 0;
    height: 95%;
    padding: 10px;
    min-height: 400px;
    width: calc(100% - 20px);
  }
  a.download {
    float: right;
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    svg {
      margin-top: 4px;
      display: inline-block;
    }
  }
}
input[type="file"] {
  display: none;
}
label.file-upload {
  display: inline-flex;
  font-size: 1em;
  line-height: 2em;
  padding: 5px 30px;
  border-radius: 10px;
  border: 1px solid var(--pac-purple);
  box-shadow: 0px 2px 4px rgba(37, 29, 68, 0.06);
  background: var(--pac-purple);
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s, box-shadow 0.2s, color 0.2s;
  .svg-container {
    margin-left: 4px;
  }
  svg {
    fill: #fff;
  }
  &:hover {
    background: rgba(133, 107, 255, 0.8);
    border-color: var(--pac-purple-light);
    box-shadow: 0px 4px 4px rgba(146, 142, 161, 0.08),
      0px 4px 8px rgba(37, 29, 68, 0.16), 0px 2px 4px rgba(50, 50, 93, 0.1);
  }
}
</style>
