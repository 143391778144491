
<template>
  <main>
    <Header />
    <div id="app">
      <router-view/>
    </div>
  </main>
</template>
<script>
import Header from '../src/components/Header.vue'
export default{
  components:{
    Header
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/main.scss';
</style>