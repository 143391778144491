<template>
  <section id="process" class="container is-max-widescreen">
    <div>
      <!-- eslint-disable -->
      <div class="results-header">
        <h1>Batch Results</h1>
        <button @click="exportSheet" class="download-wrap">
          Export Results <BaseIcon :icon="`icon-download`" />
        </button>
      </div>
      <div v-if="batchItems.length == 0" class="preview">
        <h2>
          Based on your provided Data, this is how we packed Order
          {{ activeOrderId }}
        </h2>
        <!-- errors here -->
        <ul class="errors" v-if="errorList.length > 0">
          <li v-for="(error, index) in errorList" :key="index">
            <strong>{{ error.error }}</strong> {{ error.message }}
          </li>
          <li><button>Clear Errors</button></li>
        </ul>
        <div class="packed-wrap">
          <carton-detail
            v-for="(box, index) in activeResponse.boxes"
            :box="box.box"
            :key="index"
            :svg="activeResponse.svgs[index]"
          />
          <h4 id="leftovers" v-if="activeResponse.lenLeftovers > 1">
            Items Not Packed {{ activeResponse.lenLeftovers }}
          </h4>
        </div>
        <div v-if="batchItems.length == 0">
          <button @click="packAll(parsedOrders)">
            Process Remaining Orders
          </button>
        </div>
        <!-- batch data here -->
      </div>
      <div v-if="batchItems.length < maxOrders" class="progress-wrap">
        <label for="file"
          >Processing {{ batchItems.length }}/{{ maxOrders }}</label
        >
        <progress id="file" :value="batchItems.length" :max="maxOrders">
          {{ batchItems.length / maxOrders }}%
        </progress>
      </div>

      <batch-results
        v-if="completed || batchItems.length > 0"
        :results="batchItems"
      />
    </div>
    <!-- <div>
      <router-link to="/review">Back</router-link>
      <router-link to="/">Home</router-link>
    </div> -->
    <FooterNav
      :next="`/`"
      :back="`/review`"
      :next-on-click="
        () => {
          return null;
        }
      "
      :next-active="false"
      :hide-next="true"
    />
  </section>
</template>
<script>
// import { PackedView } from "@paccurate/paccurate-ui";
import sha256 from "crypto-js/md5";
import { packConfig } from "../services/packer";
import {
  batchAverages,
  newAverages,
  cartonAverages,
  itemAverages,
} from "../services/data-helpers";
import BatchResults from "../components/BatchResults.vue";
import CartonDetail from "../components/CartonDetail";
import BaseIcon from "../components/BaseIcon";
import FooterNav from "../components/FooterNav";
import xlsx from "xlsx";
export default {
  name: "Process",
  components: {
    // PackedView,
    BatchResults,
    CartonDetail,
    FooterNav,
    BaseIcon,
  },
  data() {
    return {
      limit: 100,
      rules: [],
      activeConfigIndex: 0,
      batchItemIndex: 0,
      maxOrders: 0,
      selected: false,
      configured: false,
      processing: false,
      completed: false,
      compiledOrders: [],
      // orderData.boxTypeChoiceGoal = "most-items";
      //       orderData.itemInitialOrientationPreferred = true;
      //       orderData.itemOrientationSearchDepth = 2;
      packPrefs: {
        boxTypeChoiceGoal: "most-items",
        itemInitialOrientationPreferred: true,
        itemOrientationSearchDepth: 1,
      },
      batchResponse: [],
    };
  },
  computed: {
    packMerge() {
      return this.$store.state.rules.rulesConfig;
    },
    orderKeys() {
      return Object.keys(this.$store.state.orders.ordersReduced).slice(0, 1000);
    },
    parsedOrders() {
      return this.$store.state.orders.orders;
    },
    activeResponse() {
      return this.$store.state.orders.activeResponse;
    },
    activeOrderId() {
      return this.$store.state.orders.activeOrderId;
    },
    orderIds() {
      return this.$store.state.orders.ordersReduced;
    },
    // maxOrders() {
    //   const orderCount = Object.keys(
    //     this.$store.state.orders.ordersReduced
    //   ).length;
    //   const max = orderCount > 1000 ? 1000 : orderCount;
    //   return max;
    // },
    errorList() {
      return this.$store.state.orders.errors;
    },
    orderKeyMap() {
      return this.$store.state.orders.keyMap.orders;
    },
    batchItems() {
      return this.$store.state.orders.batchResponseItems;
    },
    batchComplete() {
      return this.$store.state.orders.batchComplete;
    },
  },
  mounted() {
    this.$store.commit("udpateActiveStep", { val: "Results" });
    for (let i = 0; i < this.parsedOrders.length; i++) {
      const target = this.parsedOrders[i];
      const store = this.$store;
      const orderKey = this.orderKeyMap["orderId"];
      if (target[this.orderKeyMap["orderId"]] !== this.orderKeyMap["orderId"]) {
        const orderItems = this.buildOrderId(
          target[orderKey],
          this.parsedOrders
        );

        const expandedOrder = this.loadConfig(false, orderItems);
        const mergedOrder = Object.assign(
          {},
          this.$store.state.rules.rulesConfig,
          expandedOrder
        );
        this.$store.commit("setOrder", target[this.orderKeyMap["orderId"]]);
        packConfig(mergedOrder).then((response) => {
          store.commit("setResponse", response.data);
          this.compiledOrders.push(target[this.orderKeyMap["orderId"]]);
        });
        break;
      }
    }
  },
  methods: {
    itemHandler(val, type) {
      const refMap = this.$store.state.orders.keyMap[type];
      let clean = {};
      if (type === "items") {
        const floatAttrs = ["length", "width", "height", "weight"];
        const numAttrs = ["price", "refId"];
        const stringAttrs = ["name", "sequence"];
        Object.keys(val).forEach((key) => {
          if (floatAttrs.includes(key)) {
            clean[key] = parseFloat(val[refMap[key]]);
          }
          if (numAttrs.includes(key)) {
            clean[key] = parseInt(val[refMap[key]]);
          }
          if (stringAttrs.includes(key)) {
            clean[key] = val[refMap[key]].toString();
          }
        });
        if (val.quantity) {
          clean.quantity = val.quantity;
        }
      }
      if (type === "cartons") {
        const floatAttrs = ["length", "width", "height", "weight", "weightMax"];
        const numAttrs = ["price", "refId"];
        const stringAttrs = ["name"];
        Object.keys(val).forEach((key) => {
          if (floatAttrs.includes(key)) {
            clean[key] = parseFloat(val[refMap[key]]);
          }
          if (numAttrs.includes(key)) {
            clean[key] = parseInt(val[refMap[key]]);
          }
          if (stringAttrs.includes(key)) {
            clean[key] = val[refMap[key]].toString();
          }
        });
      }
      const dims = {
        x: parseFloat(val[refMap.dimensions.x]),
        y: parseFloat(val[refMap.dimensions.y]),
        z: parseFloat(val[refMap.dimensions.z]),
      };
      clean.dimensions = dims;
      return clean;
    },
    processedOrders() {
      const orderMap = this.$store.state.orders.batchResponseItems.map(
        (item) => {
          return item.orderId;
        }
      );
      return orderMap;
    },
    sanitizeRefs(items) {
      const itemList = items.map((item) => {
        if (item.refId && typeof item.refId !== "number") {
          // if non-integer, pass the refId as name and then delete the refId
          item.name = item.name
            ? item.refId + " " + item.name
            : item.refId.toString();
          delete item.refId;
        }
        return item;
      });
      return itemList;
    },
    buildOrderId(id, arr) {
      const items = [];
      const orderKey = this.orderKeyMap["orderId"];
      arr.forEach((orderLine) => {
        if (orderLine[orderKey] === id) {
          items.push(orderLine);
        }
      });
      return items;
    },
    packAll(list) {
      const limit = list.length;
      // const limit = 100;
      this.processing = true;
      // how many workers do we need based on length
      // 8 is max
      const workerLength = Math.min(8, Math.ceil(limit / 1000));
      // create worker arrays
      const workerArrays = [...Array(workerLength).keys()].map(() => []);
      // clone lists
      const clonedCartons = JSON.parse(
        JSON.stringify(this.$store.state.orders.cartons)
      );
      const clonedItems = JSON.parse(
        JSON.stringify(this.$store.state.orders.items)
      );
      const clonedKeys = JSON.parse(
        JSON.stringify(this.$store.state.orders.keyMap)
      );
      const packMerge = JSON.parse(
        JSON.stringify(this.$store.state.rules.rulesConfig)
      );
      // append job id
      const dateString = new Date().toLocaleDateString();
      const hashedFileName = `bclnt_${sha256(
        this.$store.state.orders.activeSheet
      )}_${dateString.replaceAll("/", "_")}`;
      packMerge.requestId = hashedFileName;
      // loop through the orders
      for (let i = 0; i < limit; i++) {
        const target = list[i];
        const orderId = target[this.orderKeyMap["orderId"]];
        const hashed = sha256(orderId);
        const workerIndex =
          parseInt(Number(`0x${hashed.toString()}`), 16) % workerLength;
        workerArrays[workerIndex].push(target);
        if (i === limit - 1) {
          workerArrays.forEach((arr) => {
            const worker = new Worker("batch-builder.js");
            worker.postMessage({
              slice: arr,
              keyVal: this.orderKeyMap["orderId"],
              items: clonedItems,
              cartons: clonedCartons,
              keyMap: clonedKeys,
              options: packMerge,
              key: process.env.VUE_APP_PACCURATE_API_KEY,
            });
            worker.onmessage = (event) => {
              // got the batch results
              if (event.data.results) {
                const batchResults = event.data.results;
                // batchResults.forEach((order) => {
                //   if (Object.keys(order).length > 0) {
                //     this.$store.commit("pushResponseData", {
                //       orderId: order.orderId,
                //       packData: order,
                //     });
                //   }

                // });
                this.$store.commit("joinResponseData", batchResults);
                // update averages
                const averages = this.$store.state.orders.averages;
                // if it hasn't been set yet, create the averages object from the response in the store (updated in line 301)
                if (Object.keys(averages).length == 0) {
                  const responseList =
                    this.$store.state.orders.batchResponseItems;
                  const average = batchAverages(responseList);
                  this.$store.commit("setAverages", average);
                  // otherwise, use the averages object as baseline and pass the new batch to merge in
                } else {
                  const newAvg = newAverages(batchResults, averages);
                  this.$store.commit("setAverages", newAvg);
                }
                if (this.batchItems.length >= this.maxOrders) {
                  this.completed = true;
                  this.$store.commit("setComplete", true);
                }
                if (this.batchItems.length == this.maxOrders) {
                  this.processing = false;
                }
                this.batchItemIndex += batchResults.length;
              }
              // workers will post totals
              if (event.data.orderCount) {
                // error will return a negative orderCount of 1 to deprecate maxOrders
                this.maxOrders += event.data.orderCount;
              }
              // push if error
              if (event.data.error) {
                this.$store.commit("logError", {
                  error: "order error",
                  message: `${event.data.error}`,
                });
              }
            };
          });
        }
      }
    },
    loadConfig(id, orderData) {
      // build request config
      // get refIds for this orderId
      const itemRefKey = this.$store.state.orders.keyMap.items["refId"];
      const itemNameKey = this.$store.state.orders.keyMap.items["name"];
      const orderQuantityKey =
        this.$store.state.orders.keyMap.orders["quantity"];
      const orderRefKey = this.$store.state.orders.keyMap.orders["refId"];
      const itemList = this.$store.state.orders.items || this.clientRaw.items;
      const cartonList =
        this.$store.state.orders.cartons || this.clientRaw.cartons;
      const itemsLookup = {};
      if (id) {
        this.orderIds[id].forEach((item) => {
          itemsLookup[item[itemRefKey]] = item;
        });
      }
      if (orderData) {
        orderData.forEach((orderLine) => {
          itemsLookup[orderLine[orderRefKey]] = orderLine;
        });
      }

      const orderItems = itemList
        .filter((item) => {
          item.name = item[itemNameKey] ? item[itemNameKey].toString() : "";
          const exists = (orderItem) => {
            return (
              (orderItem[itemRefKey] &&
                Object.keys(itemsLookup).includes(
                  orderItem[itemRefKey].toString()
                )) ||
              Object.keys(itemsLookup).includes(
                orderItem[itemNameKey].split(" ")[0].toString()
              )
            );
          };
          return exists(item);
        })
        .map((item) => {
          const lookup = item[itemRefKey]
            ? item[itemRefKey]
            : item[itemNameKey].split(" ")[0];
          item.quantity = parseInt(itemsLookup[lookup][orderQuantityKey]);
          return item;
        });
      //
      if (orderItems.length === 0) {
        this.$store.commit("logError", {
          error: "order error",
          message: `Order ${id}, could not find item data for ${Object.keys(
            itemsLookup
          ).join(", ")}`,
        });
      }
      const config = {
        boxTypes: cartonList.map((item) => {
          return this.itemHandler(item, "cartons");
        }),
        // before we assign the itemsets, we need to audit the refs
        itemSets: this.sanitizeRefs(orderItems).map((item) => {
          return this.itemHandler(item, "items");
        }),
      };
      return config;
    },
    packOrder(orderData, orderId) {
      const store = this.$store;
      packConfig(orderData).then((response) => {
        store.commit("pushResponseData", {
          orderId: orderId,
          packData: response.data,
        });
      });
    },
    processBatch() {
      this.processing = true;
      const length = this.orderKeys.length;
      if (length < 100) {
        // small batch processing
        for (let i = 1; i < length; i++) {
          const orderData = this.loadConfig(this.orderKeys[i]);
          this.batchItemIndex = i;
          if (orderData.itemSets.length > 0) {
            const store = this.$store;
            this.loadedOrder = this.orderKeys[i];
            orderData.boxTypeChoiceGoal = "most-items";
            orderData.itemInitialOrientationPreferred = true;
            orderData.itemOrientationSearchDepth = 2;
            packConfig(orderData)
              .then((response) => {
                store.commit("pushResponseData", {
                  orderId: this.orderKeys[i],
                  packData: response.data,
                });
                this.batchResponse.push({
                  orderId: this.orderKeys[i],
                  packData: response.data,
                });
              })
              .catch((e) => {
                console.error("pack error", e);
              });
          }
          // if (i === length - 1) {
          //   this.processing = false;
          //   this.completed = true;
          // }
        }
      } else {
        // const count = Math.ceil(length/1000)
        for (let i = 1; i < length; i++) {
          // give orders more time because they need to be compressed
          const timeOut = i * 200;
          // let batchIndex = this.batchItemIndex
          setTimeout(() => {
            const orderData = this.loadConfig(this.orderKeys[i]);
            this.batchItemIndex = i;
            if (orderData.itemSets.length > 0) {
              // const store = this.$store;
              this.loadedOrder = this.orderKeys[i];
              orderData.boxTypeChoiceGoal = "most-items";
              orderData.itemInitialOrientationPreferred = false;
              orderData.itemOrientationSearchDepth = 2;
              // packConfig(orderData).then((response) => {
              //   store.commit("pushResponseData", {
              //     orderId: this.orderKeys[i],
              //     packData: response.data,
              //   });
              // });
              this.packOrder(orderData, this.orderKeys[i]);
            }
            // if (i === length - 1) {
            //   this.processing = false;
            //   this.completed = true;
            // }
          }, timeOut);
        }
      }
    },
    clearErrors() {
      this.$store.commit("clearErrors");
    },
    exportSheet() {
      const book = xlsx.utils.book_new();
      const averageValues = [this.$store.state.orders.averages];
      const cartonBreakdown = this.$store.state.orders.batchResponseItems.map(
        (obj) => {
          obj.boxes.forEach((box) => {
            box.orderId = obj.orderId;
          });
          return obj.boxes;
        }
      );
      const cartonsFlattened = [].concat.apply([], cartonBreakdown);
      const itemBreakdown = cartonsFlattened.map((boxList) => {
        if (typeof boxList.itemSummary !== "undefined") {
          return boxList.itemSummary;
        }
      });
      const itemsFlattened = [].concat.apply([], itemBreakdown);
      const itemsAverageRollup = itemAverages(itemsFlattened);
      const cartonsAverageRollup = cartonAverages(cartonsFlattened);
      const orderResponses = this.$store.state.orders.batchResponseItems.map(
        (obj) => {
          let boxString = [];
          Object.keys(obj.boxCount).forEach((boxLabel) => {
            boxString.push(`${boxLabel}:${obj.boxCount[boxLabel]}`);
          });
          obj.boxCount = boxString.join(", ");
          return obj;
        }
      );
      const avgSheet = xlsx.utils.json_to_sheet(averageValues);
      const orderSheet = xlsx.utils.json_to_sheet(orderResponses);
      const itemAvgSheet = xlsx.utils.json_to_sheet(itemsAverageRollup);
      const cartonSheet = xlsx.utils.json_to_sheet(cartonsFlattened);
      const cartonAvgSheet = xlsx.utils.json_to_sheet(cartonsAverageRollup);
      xlsx.utils.book_append_sheet(book, avgSheet, "Overview", true);
      xlsx.utils.book_append_sheet(book, orderSheet, "Order Data", true);
      xlsx.utils.book_append_sheet(
        book,
        itemAvgSheet,
        "Item Average Data",
        true
      );
      xlsx.utils.book_append_sheet(book, cartonSheet, "Carton Raw Data", true);
      xlsx.utils.book_append_sheet(
        book,
        cartonAvgSheet,
        "Carton Average Data",
        true
      );
      const dateString = new Date().toLocaleDateString();
      xlsx.writeFile(book, `Batch-${dateString}.xlsx`, { bookType: "xlsx" });
    },
  },
};
</script>
<style lang="scss">
#process {
  // padding: 0 5rem;
  h1 {
    display: inline-block;
  }
  line.volume-line {
    stroke: #16161d;
    stroke-dasharray: 2, 1;
    stroke-width: 1;
    stroke-opacity: 0.7;
  }
  ul.errors {
    float: right;
  }
  .results-header {
    margin: 1rem 0 2rem;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    .download-wrap {
      float: right;
      .svg-container {
        margin-left: 0.5rem;
      }
    }
  }
  .carton-wrap {
    margin-right: 2rem;
  }
  .preview {
    margin-bottom: 2rem;
  }
  .packed-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .progress-wrap {
    margin: -1rem 0 2rem 0;
    position: relative;
    progress[value] {
      /* Reset the default appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Get rid of default border in Firefox. */
      border: none;
      // border-radius: 10px;
      width: 100%;
      height: 2px;
      // background-image: linear-gradient(
      //   90deg,
      //   rgba(238, 67, 153, 0.8) 0%,
      //   rgba(0, 52, 239, 0.8) 100%
      // );
      background: var(--ui-grey-80);
      &::-webkit-progress-bar {
        background: var(--ui-grey-80);
      }
      &::-webkit-progress-value {
        background-image: linear-gradient(
          90deg,
          rgba(238, 67, 153, 0.8) 0%,
          rgba(0, 52, 239, 0.8) 100%
        );
      }
      &::-moz-progress-bar {
        background-image: linear-gradient(
          90deg,
          rgba(238, 67, 153, 0.8) 0%,
          rgba(0, 52, 239, 0.8) 100%
        );
      }
    }
  }
}
</style>
