<template>
  <div class="kpi-card">
    <div class="icon-wrap">
      <BaseSVG :icon="icon" />
    </div>
    <h4>{{ title }}</h4>
    <strong>{{ value }}</strong
    ><span>{{ label }}</span>
  </div>
</template>
<script>
import BaseSVG from "./BaseIcon";
export default {
  name: "KPICard",
  components: {
    BaseSVG,
  },
  props: {
    title: {
      Type: String,
      default: "Title",
    },
    icon: {
      Type: String,
      default: "items_icon",
    },
    value: {
      Type: String,
      default: "Value",
    },
    label: {
      Type: String,
      default: "Label",
    },
  },
};
</script>
<style lang="scss">
.kpi-card {
  border-radius: 10px;
  display: inline-block;
  background: #fff;
  // box-shadow: 0px 8px 8px rgba(146, 142, 161, 0.08),
  //   0px 2px 7px rgba(50, 50, 93, 0.1);
  margin: 0 1rem 1rem 0;
  min-width: 20%;
  width: calc(33.3333% - 2.5rem);
  padding: 0.75rem;
  strong {
    margin-right: 0.25rem;
  }
  span {
    color: var(--body-gray);
  }
  h4 {
    font-weight: 200;
    margin: 0 0 0.25rem 0.5rem;
  }
  .icon-wrap {
    background: #f9f8fa;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    float: left;
    margin-right: 1rem;
    padding: 0.5rem;
    svg {
      height: 30px;
      width: 30px;
      fill: #856bff;
      margin: 5px;
    }
  }
}
</style>
