<template>
  <section id="results">
    <div class="batch-kpis columns is-12 is-multiline">
      <div class="toggle-wrap">
        <span class="card-toggle" @click="toggleAllCards"
          >View All <BaseIcon icon="chev-right"
        /></span>
      </div>
      <!-- <h2>Analyzed {{ averages().total }} Orders</h2>
      <h3><small>avg cost</small>{{ averages().cost }}</h3>
      <h3><small>avg carton count</small>{{ averages().cartons }}</h3> -->
      <KPICard
        v-for="kpi in Object.keys(kpiList)"
        :key="kpi"
        :value="averages()[kpi]"
        :icon="kpiList[kpi].icon"
        :title="kpiList[kpi].title"
        :label="kpiList[kpi].label"
      />
    </div>
    <div class="columns results-wrap">
      <div class="batch-table column is-three-fifths">
        <h2>Orders</h2>
        <div class="search-wrap">
          <input
            ref="searchField"
            type="text"
            placeholder="Search Order Ids"
            @input="debounceSearch"
          />
          <ul class="search-results" v-if="searchResults.length > 0">
            <li>
              OrderId
              <span
                @click="
                  () => {
                    clearSearch();
                  }
                "
                >clear</span
              >
            </li>
            <li
              v-for="(res, index) in searchResults"
              @click="activateOrder(res)"
              :key="`res-${res.orderId}-${index}`"
            >
              {{ res.orderId }}
            </li>
          </ul>
        </div>
        <table>
          <tr>
            <th>Order Id</th>
            <th>Total Cost</th>
            <th>Items Packed</th>
            <th>Cartons Used</th>
            <th>Volume Used</th>
            <!-- <th>Items not packed</th> -->
          </tr>
          <tr
            v-for="(order, index) in orderResults().slice(
              startIndex,
              maxOrders
            )"
            :key="index"
            @click="activateOrder(order)"
            :class="activeOrder.orderId == order.orderId ? 'active' : ''"
          >
            <td>{{ order.orderId || index }}</td>
            <td>{{ totalCost(order) }}</td>
            <td>{{ totalItems(order) }}</td>
            <td>{{ totalCartons(order) }}</td>
            <td>{{ totalVolume(order) }}</td>
            <!-- <td>{{ leftovers(order) }}</td> -->
          </tr>
        </table>
        <!-- pagination -->
        <Pagination
          :max-pages="5"
          :total-pages="Math.ceil(orderResults().length / maxOrders)"
          :click="pageUpdate"
        />
      </div>
      <aside class="active-order column is-two-fifths">
        <h2>
          Order Details
          <span
            v-if="Object.keys(activeConfig).length > 0"
            @click="downloadOrder(activeOrder)"
            >Download Order <BaseIcon :icon="`icon-download`"
          /></span>
          <a
            :href="activeOrderLink()"
            v-if="Object.keys(activeConfig).length > 0"
            target="_blank"
            >Inspect Order <BaseIcon :icon="`external-link`"
          /></a>
        </h2>
        <h4>{{ loadingMsg || activeOrder.orderId }}</h4>
        <strong
          >Cartons <span>{{ activeOrder.lenBoxes }}</span></strong
        >
        <div id="cartons-inner">
          <carton-detail
            v-for="(box, index) in activeOrder.boxes"
            :box="box.box"
            :key="index"
            :svg="activeOrder.svgs[index]"
          />
        </div>
        <div id="cost">
          Total Cost
          <strong v-if="activeOrder.totalCost"
            >${{ Number(activeOrder.totalCost / 100).toFixed(2) }}</strong
          >
        </div>
      </aside>
    </div>
  </section>
</template>
<script>
const _ = require("lodash");
import LZString from "lz-string";
import { packConfig } from "../services/packer";
import { batchAverages } from "../services/data-helpers";
import { mapGetters } from "vuex";
import CartonDetail from "../components/CartonDetail";
import Pagination from "../components/Pagination";
import KPICard from "../components/KPICard";
import BaseIcon from "../components/BaseIcon";
import xlsx from "xlsx";
export default {
  name: "BatchResults",
  components: {
    CartonDetail,
    Pagination,
    KPICard,
    BaseIcon,
  },
  props: {
    results: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      maxOrders: 10,
      activeOrder: false,
      loadingMsg: false,
      startIndex: 0,
      kpiList: {
        total: {
          title: "Orders",
          label: "(total)",
          icon: "orders_icon",
        },
        totalCost: {
          title: "Total Cost",
          label: "USD",
          icon: "cost_icon",
        },
        cartons: {
          title: "Avg Carton Count",
          label: "(total)",
          icon: "multi-carton-icon",
        },
        cost: {
          title: "Avg Cost",
          label: "USD",
          icon: "cost_icon",
        },
        items: {
          title: "Avg Item Count",
          label: "/carton",
          icon: "items_icon",
        },
        volume: {
          title: "Avg Volume Utilization",
          label: "%",
          icon: "items_icon",
        },
        weight: {
          title: "Avg Weight",
          label: "lbs",
          icon: "items_icon",
        },
      },
      searching: false,
      searchResults: [],
      activeConfig: {},
    };
  },
  computed: {
    ...mapGetters(["getOrderById"]),
    cartonData() {
      const raw = this.$store.state.orders.batchResponseItems;
      const cartonLookup = {};
      raw.forEach((order) => {
        order.boxes.forEach((boxObj) => {
          const boxName = boxObj.box.name;
          if (typeof cartonLookup[boxName] === "undefined") {
            cartonLookup[boxName] = 1;
          } else {
            cartonLookup[boxName]++;
          }
        });
      });
      console.info(cartonLookup);
      return cartonLookup;
    },
  },
  methods: {
    averages() {
      return batchAverages(this.orderResults());
    },
    activeOrderLink() {
      const recipeData = JSON.stringify(this.activeConfig);
      const recipeURI = `https://inspector.manage.paccurate.io/config-editor?config=${LZString.compressToBase64(
        recipeData
      )}`;
      return recipeURI;
    },
    downloadOrder(order) {
      // loop through the packed boxes
      const packedBoxes = order.boxes;
      // generate box numbers and names -- EX: 1: MAC12
      const boxNames = order.boxes.map((obj, index) => {
        return `${index + 1} - ${obj.box.name}`;
      });
      const orderLines = [];
      packedBoxes.forEach((box, index) => {
        const boxName = boxNames[index];
        const items = box.box.items;
        let itemLookup = {};
        items.forEach((item) => {
          item = item.item;
          console.info(
            Object.keys(item.dimensions).map((key) => {
              return item.dimensions[key];
            }),
            item.dimensions
          );
          if (typeof itemLookup[item.name] == "undefined") {
            // IE dimensions:{x:1, y:2, z:3} flattens to [1,2,3]
            const dims = Object.keys(item.dimensions).map((key) => {
              return item.dimensions[key];
            });
            itemLookup[item.name] = {
              box: boxName,
              quantity: 1,
              weight: item.weight,
              name: item.name,
              dimensions: `[${dims.join(",")}]`,
            };
          } else {
            itemLookup[item.name].quantity++;
          }
        });
        // Create an entry in the order lines array for each item with quantity and box assignment
        Object.keys(itemLookup).forEach((key) => {
          orderLines.push(itemLookup[key]);
        });
      });
      const sheet = xlsx.utils.json_to_sheet(orderLines);
      xlsx.utils.sheet_add_aoa(
        sheet,
        [
          ["----"],
          [["Total Cost"], [Number(order.totalCost / 100).toFixed(2)]],
        ],
        { origin: -1 }
      );
      const book = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(book, sheet, `Order ${order.orderId}`);
      // const csv = xlsx.utils.sheet_to_csv(sheet);
      const dateString = new Date().toLocaleDateString();
      xlsx.writeFile(book, `Order ${order.orderId} - ${dateString}.xlsx`, {
        bookType: "xlsx",
      });
    },
    pageUpdate(index) {
      const newIndex = index * 10;
      this.startIndex = newIndex;
      this.maxOrders = newIndex + 10;
    },
    normalized(orderObj) {
      let clear = {};
      if (orderObj.packData && typeof orderObj.packData !== "undefined") {
        clear = orderObj;
      } else {
        clear = {
          packData: orderObj,
          orderId: orderObj.orderId,
        };
      }
      // console.info(orderObj, clear);
      return clear;
    },
    orderResults() {
      if (this.results && this.results.length > 0) {
        return this.results;
      } else {
        return this.$store.state.orders.batchResponseItems;
      }
    },
    totalCost(order) {
      order = this.normalized(order);
      return order.packData.totalCost;
    },
    totalCartons(order) {
      order = this.normalized(order);
      // const boxNames = order.packData.boxes
      //   .map((box) => {
      //     return box.box.name;
      //   })
      //   .join(", ");
      return `${order.packData.lenBoxes}`;
    },
    totalItems(order) {
      order = this.normalized(order);
      return order.packData.lenItems;
    },
    totalVolume(order) {
      order = this.normalized(order);
      // let avail = 0;
      // let used = 0;
      // order.packData.boxes.forEach((box) => {
      //   avail += box.box.volumeMax;
      //   used += box.box.volumeUsed;
      // });
      return `${
        order.packData.volumeUtilization
          ? (order.packData.volumeUtilization * 100).toFixed(2)
          : ""
      }%`;
    },
    activateOrder(obj) {
      obj = this.normalized(obj);
      this.activeOrder = {};
      this.loadingMsg = `Loading order ${obj.orderId}`;
      // get order items and quantities by ID
      const assembledOrder = this.getOrderById(obj.orderId);
      // merge default rules
      const packDefaults = JSON.parse(
        JSON.stringify(this.$store.state.rules.defaults)
      );
      // merge rules and options
      const packMerge = JSON.parse(
        JSON.stringify(this.$store.state.rules.rulesConfig)
      );

      const merged = Object.assign({}, assembledOrder, packMerge, packDefaults);
      if (packMerge.boxTypeChoiceGoal) {
        // if there was a specifically assigned goal use it instead of the default (which is most-items)
        merged.boxTypeChoiceGoal = packMerge.boxTypeChoiceGoal;
      }
      // make sure svgs will come back
      merged.includeImages = true;
      this.activeConfig = merged;
      packConfig(merged).then((response) => {
        this.$store.commit("setResponse", response.data);
        this.$store.commit("setOrder", obj.orderId);
        this.activeOrder = response.data;
        this.activeOrder.orderId = obj.orderId;
        this.loadingMsg = false;
      });
    },
    leftovers(order) {
      order = this.normalized(order);
      return order.packData.lenLeftovers;
    },
    loadMore() {
      this.maxOrders += 100;
    },
    clearSearch() {
      this.searching = false;
      this.searchString = false;
      this.searchResults = [];
      this.$refs.searchField.value = "";
    },
    debounceSearch: _.debounce(function (e) {
      this.searching = true;
      try {
        const resultsList = this.$store.state.orders.batchResponseItems;
        const searchString = e.target.value;
        if (searchString.length > 0) {
          const filtered = resultsList.filter((result) => {
            if (result.orderId) {
              return result.orderId
                .toLowerCase()
                .includes(searchString.toLowerCase());
            }
          });
          this.searchResults = filtered;
          this.searching = false;
        } else {
          this.searchResults = [];
          this.searching = false;
        }
      } catch (err) {
        console.error("Search error", err);
        this.searching = false;
      }
    }, 300),
    toggleAllCards() {
      // change card wrapper height to accomodate all
      document.querySelector(".batch-kpis").classList.toggle("expanded");
    },
  },
};
</script>
<style lang="scss">
#results {
  clear: both;
  .card-toggle {
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }
  .batch-kpis {
    transition: max-height 0.2s;
    max-height: 120px;
    overflow: hidden;
    &.expanded {
      max-height: 1000px;
      .toggle-wrap {
        .svg-container svg {
          transform: rotate(-90deg);
        }
      }
    }
    .toggle-wrap {
      text-align: right;
      width: 100%;
      padding-bottom: 10px;
      .svg-container svg {
        height: 0.75em;
        transform: rotate(90deg);
        transition: transform 0.2s;
      }
    }
  }
  tr {
    th,
    td {
      text-align: center;
      padding: 10px 20px;
      border-color: transparent;
      font-size: 1rem;
      text-align: left;
      font-weight: bold;
      color: #514a69;
      // &:first-of-type {
      //   text-align: left;
      // }
      // &:last-of-type {
      //   text-align: right;
      // }
    }
    &:first-of-type {
      position: sticky;
      top: 0;
      background: #fbfbff;
      th {
        text-transform: uppercase;
        font-size: 0.85rem;
        color: #928ea1;
      }
    }
    &:hover {
      background: #f0edff;
      cursor: pointer;
      td {
        color: #251d44;
      }
    }
    &.active {
      background: #f0edff;
    }
  }
  table {
    max-height: 800px;
    border-spacing: 0;
  }
  .batch-table,
  .active-order {
    max-height: 100vh;
  }
  .active-order {
    position: relative;
    width: calc(40% - 3rem);
    h2 span {
      float: right;
      color: var(--brand-dark-purple-80);
      background: var(--brand-dark-purple-30);
      padding: 5px 10px;
      border-radius: 20px;
      text-decoration: none;
      font-size: 0.85rem;
      font-weight: 200;
      svg {
        height: 0.7rem;
        width: 0.7rem;
      }
      &:hover {
        background: var(--brand-dark-purple-20);
        cursor: pointer;
      }
    }
    a {
      float: right;
      color: var(--brand-pink-accent-100);
      background: var(--brand-pink-accent-10);
      padding: 5px 10px;
      border-radius: 20px;
      text-decoration: none;
      font-size: 0.85rem;
      font-weight: 200;
      svg {
        height: 0.7rem;
        width: 0.7rem;
      }
      &:hover {
        background: var(--brand-pink-accent-20);
      }
    }
    h4,
    strong {
      color: var(--brand-dark-purple-50);
      span {
        display: inline-block;
        background: var(--ui-grey-50);
        color: var(--brand-dark-purple-80);
        margin-left: 1rem;
        border-radius: 5px;
        padding: 0px 5px;
      }
    }
    .carton-wrap {
      width: calc(100% - 1.5rem);
      margin-left: 0.75rem;
    }
  }
  .search-wrap {
    position: relative;
    input {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      border: 0;
      font-size: 1rem;
      padding: 0.5rem 1rem 0.5rem calc(1rem + 20px);
      margin-bottom: 1rem;
      position: relative;
      display: inline-block;
      width: calc(100% - 4rem);
    }
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      left: 10px;
      top: 8px;
      background: url("../assets/img/search_icon.svg") 50% 50% no-repeat;
      position: absolute;
      z-index: 3;
      background-size: contain;
    }
  }
  ul.search-results {
    position: absolute;
    background: #fff;
    z-index: 200;
    margin: 0;
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid var(--ui-grey-100);
    border-radius: 0 0 4px 4px;
    li {
      width: 100%;
      padding: 0.6rem 0.3rem;
      &:first-of-type {
        padding: 0.3rem 0.3rem 0.3rem 0.6rem;
        margin-bottom: 0.3rem;
        border-bottom: 1px solid var(--brand-dark-purple-20);
        color: #928ea1;
        pointer-events: none;
        span {
          display: inline-block;
          float: right;
          padding: 5px 10px;
          font-size: 0.66rem;
          border-radius: 20px;
          color: var(--brand-dark-purple-80);
          background: var(--brand-dark-purple-20);
          margin-right: 10px;
          margin-top: -2px;
          pointer-events: auto;
          &:hover {
            background: var(--brand-dark-purple-30);
          }
        }
      }
      &:hover {
        background: #e5dfff;
        cursor: pointer;
      }
    }
  }
  .results-wrap {
    border: 1px solid #e2e1ea;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    color: var(--brand-dark-purple-80);
    h2 {
      margin-top: 0;
    }
  }
  .pagination {
    background: #fbfbff;
    display: flex;
    justify-content: right;
    span {
      border-radius: 5px;
      display: inline-block;
      padding: 4px 8px;
      margin: 8px 2px;
      color: #928ea1;
      font-weight: bold;
      &.active,
      &:hover {
        background: #e5dfff;
        cursor: pointer;
      }
    }
  }
  #cartons-inner {
    overflow-y: auto;
    margin: 0.5rem 0 6rem 0;
    max-height: 25vh;
  }
  #cost {
    strong {
      float: right;
      color: #251d44;
      font-size: 1.25rem;
    }
    background: #fbfbff;
    padding: 2rem;
    font-weight: 600;
    color: #928ea1;
    font-size: 1rem;
    position: absolute;
    bottom: 1.5rem;
    width: calc(100% - 7rem);
    display: block;
  }
}
</style>
