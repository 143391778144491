<template>
  <table class="default-table">
    <slot></slot>
  </table>
</template>
<script>
export default {
  name: "BaseTable",
};
</script>
<style lang="scss">
table.default-table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  td,
  th {
    padding: 10px 15px;
    text-align: left;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 20;
    th {
      border-bottom: 1px solid var(--light-gray);
      border-top: 1px solid var(--light-gray);
      background: #fbfbff;
      text-transform: uppercase;
      &:first-of-type {
        border-radius: 10px 0 0 0;
        border-left: 1px solid var(--light-gray);
      }
      &:last-of-type {
        border-radius: 0 10px 0 0;
        border-right: 1px solid var(--light-gray);
      }
    }
  }
  td {
    border-bottom: 1px solid var(--light-gray);
    font-size: 13px;
    position: relative;

    &:first-of-type {
      border-left: 1px solid var(--light-gray);
    }
    &:last-of-type {
      border-right: 1px solid var(--light-gray);
    }
  }
}
</style>
