<script>
import { mapMutations } from "vuex";
import xlsx from "xlsx";
import FooterNav from "@/components/FooterNav";
import BaseIcon from "@/components/BaseIcon";
import UploadProgress from "@/components/UploadProgress";
import { containsAll } from "../services/data-helpers";
export default {
  name: "Analyze",
  components: {
    FooterNav,
    UploadProgress,
    BaseIcon,
  },
  data() {
    return {
      request: {},
      clientRaw: {},
      showItems: false,
      showBoxes: false,
      activeTab: "raw",
      activeConfig: false,
      loadingSheet: false,
      nowParsing: {},
      limit: 100,
      orderIds: {},
      sheetName: "",
    };
  },
  computed: {
    activeSheet() {
      return this.$store.state.orders.activeSheet;
    },
    parsedSheets() {
      return this.$store.state.orders.parsedSheets;
    },
  },
  methods: {
    ...mapMutations(["completedStep", "clearUserData"]),
    async handleSheet(e) {
      this.reset();
      this.loadingSheet = true;
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      this.sheetName = file.name;
      this.$store.commit("setActiveSheet", file.name);
      const targetSheets = ["Items", "Cartons", "Orders"];
      const data = await file.arrayBuffer();
      const workbook = xlsx.read(data);
      // workbook is the result of an promise
      if (workbook) {
        if (containsAll(Object.keys(workbook.Sheets), targetSheets)) {
          //  if we have all of the sheets we need in the workbook, assign each a web worker to parse through
          targetSheets.forEach((sheet) => {
            const workerCb = this.workbookHandler(sheet, workbook);
            const worker = new Worker("batch-worker.js");
            worker.postMessage({ callback: workerCb });
          });
        } else {
          // log error
          this.$store.commit("logError", {
            error: "Sheet Error",
            message: `Missing required sheet. Please confirm xls contains sheets "Items", "Orders", and "Cartons". Provided Sheets were: ${Object.keys(
              workbook.Sheets
            ).join(", ")}`,
          });
        }
        if (e.dataTransfer) {
          this.toggleHover(e);
        }
      } else {
        if (e.dataTransfer) {
          this.toggleHover(e);
        }
      }
    },
    throttleJson({ sheet, length, range, name }) {
      const store = this.$store;
      // update UI while loading
      this.$set(this.nowParsing, `${name}`, { count: "", total: length });
      // column headers are keys
      const firstRows = xlsx.utils.sheet_to_json(sheet, {
        defval: "",
        range: `${range[0]}1:${range[1]}2`,
      });
      const keys = Object.keys(firstRows[0]);
      // push the column headers to store for this type of data to be used in assignment later
      store.commit("setUserKeys", { target: name.toLowerCase(), values: keys });
      const targetLength = length;
      if (targetLength > 1000 || name === "Orders") {
        const count = Math.ceil(targetLength / 1000);
        for (let i = 0; i < count; i++) {
          // first pass we need to use 2 instead of 1 to skip column headers
          const inc = i == 0 ? 2 : 1;
          const startRow = i * 1000 + inc;
          const endRow = startRow + 1000;
          // give orders more time because they need to be compressed
          const timeOut = name === "Items" ? i * 200 : i * 500;
          setTimeout(() => {
            // this assignment is compute-heavy but takes each item in the sheet, turns it into a json object, and returns an array of objects.
            const list = xlsx.utils.sheet_to_json(sheet, {
              header: keys,
              defval: "",
              raw: false,
              range: `${range[0] + startRow}:${range[1] + endRow}`,
            });
            this.$set(this.nowParsing, `${name}`, {
              count: `${startRow} - ${endRow}`,
              total: targetLength,
            });
            store.commit("updateList", {
              target: name.toLowerCase(),
              values: list,
            });
          }, timeOut);
          if (i == count) {
            this.loadingSheet = false;
          }
        }
      } else {
        const list = xlsx.utils.sheet_to_json(sheet);
        this.$set(this.nowParsing, `${name}`, {
          count: `${length}`,
          total: length,
        });
        store.commit("updateList", {
          target: name.toLowerCase(),
          values: list,
        });
        this.loadingSheet = false;
      }
      store.commit("updateParsedSheets", this.nowParsing);
    },
    toggleHover(e) {
      // hover state on drop zone
      const target = e.target;
      if (target.classList.contains("hover")) {
        target.classList.remove("hover");
      } else {
        target.classList.add("hover");
      }
    },
    workbookHandler(sheet, workbook) {
      const targetSheet = workbook.Sheets[sheet];
      // example values for the whole sheet are "A1:G257151"
      // to get the number of rows, we need to subtract those start and end indexes 257151-1
      const sheetLength = targetSheet["!ref"]
        .split(":")
        .map((index) => {
          // G257151 becomes 257151
          const int = parseInt(index.replace(/[^\d.-]/g, ""));
          return int;
        })
        .reduce((prev, current) => {
          return current - prev;
        });
      const sheetColumnRange = targetSheet["!ref"].split(":").map((index) => {
        // this returns 1-257151 as a string
        return index.replace(/[0-9\n]/g, "");
      });
      // hand sheet data, name, and ranges over to the method that turns each sheet to JSON and puts it in the store
      this.throttleJson({
        sheet: targetSheet,
        length: sheetLength,
        range: sheetColumnRange,
        name: sheet,
      });
    },
    reset() {},
  },
};
</script>
<template>
  <section id="upload" class="container">
    <h1>New Batch Analysis</h1>
    <h3>Upload your spreadsheet</h3>
    <p>
      Upload your .xls file containing sheets for
      <strong>cartons, items, and orders</strong>
      below or learn how to format your file by using our template.
    </p>
    <div
      class="excel-upload"
      @drop.prevent="handleSheet"
      @dragover.prevent
      @dragenter.prevent="toggleHover"
      @dragleave.prevent="toggleHover"
    >
      <div class="input-wrap">
        Drop your spreadsheet here or
        <label for="sheet">
          browse
          <input
            type="file"
            @change="handleSheet($event)"
            id="sheet"
            name="sheet"
          />
        </label>
      </div>
      <div
        v-if="activeSheet"
        :class="`sheet-name ${loadingSheet ? 'loading' : ''}`"
      >
        {{ activeSheet }}
        <span @click="clearUserData()" class="cancel"
          ><BaseIcon :icon="`stop_icon`"
        /></span>
      </div>
    </div>
    <div id="processing-sheet" v-if="loadingSheet"></div>
    <!-- <upload-progress :count="125" :total="250" :label="'CARTONS'" /> -->
    <div
      class="prog-item-wrap"
      :class="Object.keys(parsedSheets).length > 0 ? 'show' : null"
    >
      <upload-progress
        v-for="loading in Object.keys(parsedSheets)"
        :key="loading"
        :label="loading.toUpperCase()"
        :count="parsedSheets[loading].count"
        :total="parsedSheets[loading].total"
      />
    </div>
    <hr />
    <div class="columns">
      <div class="column is-two-thirds">
        <h3>Need Help Getting started?</h3>
        <h3 class="dark">Download a Sample Spreadsheet</h3>
        <p class="dark">
          We have created an example file to show the batch analysis in action.
          Download the small sample and test the import process to avoid any
          suprises.
        </p>
      </div>
      <div class="column">
        <div class="thumb-wrap">
          <a
            href="https://docs.google.com/spreadsheets/d/1BDbI3qfIR-xcCDKp9VxM5tj45AfLmSlE/edit?usp=sharing&ouid=109225702681044554064&rtpof=true&sd=true"
            target="_blank"
            ><img src="../assets/img/spreadsheet_thumb.png"
          /></a>
          <strong>Download</strong>
        </div>
      </div>
    </div>
    <FooterNav
      :next="`/configure`"
      :back="`/`"
      :next-on-click="completedStep('Import Data')"
      :next-active="Object.keys(parsedSheets).length > 0"
      :back-active="false"
      :hide-back="true"
    />
  </section>
</template>

<style lang="scss">
p,
h3 {
  color: var(--body-gray);
  &.dark {
    color: var(--pac-blk);
  }
}
hr {
  background: var(--light-gray);
  height: 1px;
  border: 0;
}
@keyframes spin {
  from {
    transform: rotateZ(0deg) translateZ(-1px);
  }
  to {
    transform: rotateZ(360deg) translateZ(-1px);
  }
}
.sheet-name {
  span.cancel {
    // display: inline-block;
    float: right;
    border-radius: 50%;
    background-color: var(--body-gray);
    // background-image: url("../assets/img/stop_icon.svg");
    width: 20px;
    height: 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 120%;
    .svg-container svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      cursor: pointer;
      background-color: var(--pac-purple);
    }
  }
  &.loading {
    span.cancel {
      position: relative;
      display: inline-block;
      transform-style: preserve-3d;
      fill: #fff;
      &::before {
        content: "";
        position: absolute;
        background-image: conic-gradient(
          rgba(238, 67, 153, 0.8) 0%,
          rgba(0, 52, 239, 0.8) 100%,
          var(--light-gray) 100%
        );
        width: 24px;
        height: 24px;
        top: -2px;
        left: -2px;
        border-radius: 50%;
        animation: 1000ms infinite linear spin;
        transform: rotateZ(360deg) translateZ(-1px);
      }
    }
  }
}
.excel-upload {
  padding: 4rem;
  margin-bottom: 4rem;
  background: #ffffff;
  /* Shadow */
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  border: 1px solid var(--light-gray);
  .input-wrap {
    border: 2px dashed #856bff;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 2rem 0;
    background-color: #ffffff;
    transition: 0.3s background-color;
    &.hover {
      background-color: #f9f8fa;
    }
    label {
      // display: inline-flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      // padding: 5px 20px;
      /* UI-Gray Scale 0 */
      // background: #ffffff;
      // box-shadow: 0px 2px 4px rgba(37, 29, 68, 0.24);
      // border-radius: 10px;
      // font-size: 1.25rem;
      // margin-left: 1.25rem;
      // transition: 0.3s background-color;
      color: #856bff;
      &:hover {
        // background: #f9f8fa;
        color: #251d44;
        cursor: pointer;
      }
      input[type="file"] {
        display: none;
      }
    }
  }
}
.prog-item-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  max-height: 1px;
  transition: max-height 0.3s;
  &.show {
    max-height: 800px;
  }
}
.thumb-wrap {
  border-radius: 4px;
  position: relative;
  float: right;
  margin: 20px;
  img {
    max-width: 200px;
    border-radius: 4px;
  }
  strong {
    background: linear-gradient(
      94.33deg,
      rgba(238, 67, 153, 0.8) 0.21%,
      rgba(93, 95, 239, 0.8) 97.76%
    );
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    z-index: 20;
    bottom: 0px;
    text-align: center;
    color: #fff;
    left: 0;
    width: 100%;
    padding: 5px 0;
  }
}
#upload .columns {
  padding-bottom: 120px;
}
.next-wrap {
  position: fixed;
  bottom: 0px;
  padding: 40px 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), var(--background));
  display: block;
  width: 960px;
  z-index: 300;
}
// @media only screen and (min-height: 800px) {
//   .next-wrap {
//     position: fixed;
//     bottom: 0px;
//     padding: 40px 0;
//     background-image: linear-gradient(rgba(0, 0, 0, 0), var(--background));
//     display: block;
//     width: 960px;
//     z-index: 300;
//   }
// }
</style>
